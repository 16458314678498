import React, {useEffect, useMemo, useState} from 'react'
import {SelectProps as AntdSelectProps, SelectValue} from 'antd/lib/select'

export type ValueOption = {
  value: string | number
  label: string
  style?: React.CSSProperties
}
type NameOption = {id?: number; name?: string}
export type OptionType = NameOption | ValueOption | string
export type ApiOptions = (p?: any) => Promise<OptionType[]>
export type OptionsType = OptionType[] | ApiOptions
export function isValueOption(option: OptionType): option is ValueOption {
  return (option as ValueOption).value !== undefined
}
export function isApiOptions(options: OptionsType): options is ApiOptions {
  return typeof options === 'function'
}

export const useOptions = (originOptions: OptionsType, p?: any): ValueOption[] => {
  const [options, setOptions] = useState(() => {
    if (typeof originOptions !== 'function') {
      return normalizeOptions(originOptions)
    }
    return [] as ValueOption[]
  })
  useEffect(() => {
    if (typeof originOptions === 'function') {
      originOptions(p).then((res) => setOptions(normalizeOptions(res)))
    } else {
      setOptions(normalizeOptions(originOptions))
    }
  }, [originOptions, p])
  return options
}
export interface SelectProps extends Omit<AntdSelectProps<string>, 'options'> {
  options?: OptionsType
  hasAllOption?: boolean

  // onChange?:
}

export const normalizeOptions = (options: OptionType[]): ValueOption[] => {
  if (!options) return []
  if (!Array.isArray(options)) {
    return normalizeOptions(
      Object.keys(options).map((value) => ({value, label: options[value as any]})),
    )
  }
  return options.map((option) => {
    if (isValueOption(option)) {
      return option
    } else if (typeof option === 'string') {
      return {value: option, label: option}
    } else {
      return {value: option.id, label: option.name}
    }
  })
}
export function getOptionText(value: any, options: OptionType[] = []) {
  for (let option of options) {
    if (isValueOption(option)) {
      if (option.value === value) return option.label
    } else if (typeof option === 'string') {
      if (option === value) return option
    } else if (option.id === value) {
      return option.name
    }
  }
}
export function getOptionValue(options: OptionType[], text: any) {
  for (let option of options) {
    if (isValueOption(option)) {
      if (option.label === text) return option.value
    } else if (typeof option === 'string') {
      if (option === text) return option
    } else if (option.name === text) {
      return option.id
    }
  }
}
