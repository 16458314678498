/** 消费豆子 */
export interface BeansConsumeVO {
  trainMode: TrainingModeEnum
  /** 课题id */
  coursewareId?: number
}

export enum BeansTypeEnum {
  /** 训练消费 */
  TRAIN = 1,
  /** 购买课件 */
  BUY_COURSEWARE = 2,
  /** 管理后台机构扣除 */
  ORGANIZATION = 3,
  /** 错题扣除 */
  MISTAKE = 4,
  /** 挑战擂主 */
  RING_CHAMPION = 5,
  /** 机构管理后台扣减豆子 */
  INSTITUTION_SUBTRACT_STUDENT = 8,
  /** 结构给学生充值增加学生豆子数 */
  STUDENT_RECHARGE_STU = 9,
  /** 结构给学生充值扣减机构豆子数 */
  STUDENT_RECHARGE_INS = 10,
  /** 错题退款 */
  RECHARGE_MISTAKE = 11,
  /** 课件退款 */
  RECHARGE_COURSEWARE = 12,
  /** 挑战擂主成功奖励 */
  CHAMPION_REWARD = 13,
  /** 机构充值 */
  INSTITUTION_RECHARGE = 14,
}

export const BeansTypeEnumOptions = [
  {label: '训练消费', value: 1},
  {label: '购买课件', value: 2},
  {label: '管理后台机构扣除', value: 3},
  {label: '错题扣除', value: 4},
  {label: '挑战擂主', value: 5},
  {label: '机构管理后台扣减豆子', value: 8},
  {label: '结构给学生充值增加学生豆子数', value: 9},
  {label: '结构给学生充值扣减机构豆子数', value: 10},
  {label: '错题退款', value: 11},
  {label: '课件退款', value: 12},
  {label: '挑战擂主成功奖励', value: 13},
  {label: '机构充值', value: 14},
]

export const getBeansTypeEnumText = (beansTypeEnum: BeansTypeEnum) =>
  BeansTypeEnumOptions.find((o) => o.value === beansTypeEnum)?.label
/** 豆子流水记录 */
export interface BeansWater {
  id?: number
  beansCount?: number
  effectId?: number
  institutionId?: number
  operatorType?: BeansTypeEnum
  businessId?: number
  businessContent?: string
  createdBy?: number
  createdDate?: string
}

export interface CategoryAndCoursewareDTO {
  categoryFullName?: string
  categoryId?: number
  coursewares?: Courseware[]
}

export interface ChallengeRecord {
  id?: number
  challengerId?: number
  num?: number
  during?: number
  challengeSuccess?: boolean
  grade?: GradeEnum
  ringType?: RingType
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
}

export interface ChallengeRecordDTO {
  id?: number
  challengerId?: number
  num?: number
  during?: number
  challengeSuccess?: boolean
  grade?: GradeEnum
  ringType?: RingType
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
  challengerName?: string
}

export interface ChallengeRecordSearch {
  pageIndex?: number
  pageSize?: number
  orderBy?: string
  lastId?: number
  grade?: GradeEnum
  ringType?: RingType
  createdDate?: string
  challengerId?: number
}

export interface Courseware {
  id?: number
  type?: CoursewareType
  categoryId: number
  sort?: number
  name: string
  price?: number
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
  delFlag?: number
}

export interface CoursewareCategoryTree {
  parentId?: number
  name?: string
  id?: number
  children?: CoursewareCategoryTree[]
  grade?: GradeEnum
  fullName?: string
}

/** 课件分类查询 */
export interface CoursewareCategoryVO {
  /** 课件分类父id */
  parentId?: number
  type?: CoursewareType
}

/** 课件数据 */
export interface CoursewareDTO {
  id?: number
  type?: CoursewareType
  categoryId: number
  sort?: number
  name: string
  price?: number
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
  delFlag?: number
  /** 创建人名称 */
  createdByName?: string
  /** 更新人名称 */
  updatedByName?: string
  coursewareType?: CoursewareType
  /** 课件分类名称 */
  categoryName?: string
}

export enum CoursewareType {
  /** 单词课件 */
  WORD = 1,
  /** 句子课件 */
  SENTENCE = 2,
}

export const CoursewareTypeOptions = [
  {label: '单词课件', value: 1},
  {label: '句子课件', value: 2},
]

export const getCoursewareTypeText = (coursewareType: CoursewareType) =>
  CoursewareTypeOptions.find((o) => o.value === coursewareType)?.label
/** 课件查询 */
export interface CoursewareVO {
  pageIndex?: number
  pageSize?: number
  orderBy?: string
  lastId?: number
  /** 分类id */
  categoryId?: number
  /** 年级id */
  gradeId?: number
  /** 课件状态 */
  status?: number
  /** 课件等级 */
  coursewareLevel?: number
  /** 课件类型 */
  type?: number
}

export interface DailyExamMistakeDTO {
  id?: number
  mistakeCount?: number
  coursewareId?: number
  examId?: number
  examLevel?: number
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
  cn?: string
  en?: string
  ipa?: string
  audioUrl?: string
  type?: CoursewareType
}

/** 每日数据记录 */
export interface DailyStatistic {
  id?: number
  dailyTask?: number
  dailyAdvancedTask?: number
  duration?: number
  wrongTrainingWord?: number
  wrongTrainingWordCount?: number
  wrongTrainingSentence?: number
  wrongTrainingSentenceCount?: number
  createdBy?: number
  createdDate?: string
}

/** 课件数据 */
export interface DailyStatisticDTO {
  id?: number
  dailyTask?: number
  dailyAdvancedTask?: number
  duration?: number
  wrongTrainingWord?: number
  wrongTrainingWordCount?: number
  wrongTrainingSentence?: number
  wrongTrainingSentenceCount?: number
  createdBy?: number
  createdDate?: string
  /** 每日任务指法记忆力训练 */
  fingeringAdvanced?: boolean
  /** 每日任务指法注意力训练 */
  fingering?: boolean
  /** 错题单词 */
  mistakeWord?: boolean
  /** 错题句子 */
  mistakeSentence?: boolean
  /** 阶段复习单词 */
  reviewWord?: boolean
  /** 阶段复习句子 */
  reviewSentence?: boolean
}

export interface ExamLevelCenterDTO {
  id?: number
  duration?: number
  fingeringMode?: TrainingModeEnum
  fingeringTimes?: number
  fingeringRate?: number
  niceCount?: number
  wordGrade?: GradeEnum
  wordCount?: number
  wordRate?: number
  sentenceGrade?: GradeEnum
  sentenceCount?: number
  sentenceRate?: number
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
  /** 试卷id */
  examId: number
  /** 单词数据 */
  words: Word[]
  /** 句子数据 */
  sentences: Sentence[]
}

export enum ExamLevelCenterEnum {
  /** 无等级 */
  NORMAL = 200,
  /** 青铜 */
  ONE = 201,
  /** 白银 */
  TWO = 202,
  /** 黄金 */
  THREE = 203,
  /** 铂金 */
  FOUR = 204,
  /** 砖石 */
  FIVE = 205,
  /** 星耀 */
  SIX = 206,
  /** 王者 */
  SEVEN = 207,
}

export const ExamLevelCenterEnumOptions = [
  {label: '无等级', value: 200},
  {label: '青铜', value: 201},
  {label: '白银', value: 202},
  {label: '黄金', value: 203},
  {label: '铂金', value: 204},
  {label: '砖石', value: 205},
  {label: '星耀', value: 206},
  {label: '王者', value: 207},
]

export const getExamLevelCenterEnumText = (examLevelCenterEnum: ExamLevelCenterEnum) =>
  ExamLevelCenterEnumOptions.find((o) => o.value === examLevelCenterEnum)?.label
/** 考级中心 */
export interface ExamLevelCenterVO {
  /** 考试用时 */
  duration: number
  /** 基础指法循环次数 */
  times: number
  /** 基础指法正确率 */
  rate: number
  /** 记忆力训练nice数 */
  niceCount: number
  /** 单词测评结果 */
  words: TrainContentVO[]
  /** 句子测评结果 */
  sentences?: TrainContentVO[]
}

export interface ExamQuestionsDTO {
  id?: number
  words?: Word[]
  sentences?: Sentence[]
  gradeEnum?: GradeEnum
}

export enum GenderEnum {
  /** 男 */
  MALE = 0,
  /** 女 */
  FEMALE = 1,
}

export const GenderEnumOptions = [
  {label: '男', value: 0},
  {label: '女', value: 1},
]

export const getGenderEnumText = (genderEnum: GenderEnum) =>
  GenderEnumOptions.find((o) => o.value === genderEnum)?.label
export enum GradeEnum {
  /** 一年级 */
  FIRST_GRADE = 1,
  /** 二年级 */
  SECOND_GRADE = 2,
  /** 三年级 */
  THIRD_GRADE = 3,
  /** 四年级 */
  FOURTH_GRADE = 4,
  /** 五年级 */
  FIFTH_GRADE = 5,
  /** 六年级 */
  SIXTH_GRADE = 6,
  /** 七年级 */
  SEVENTH_GRADE = 7,
  /** 八年级 */
  EIGHTH_GRADE = 8,
  /** 九年级 */
  NINTH_GRADE = 9,
}

export const GradeEnumOptions = [
  {label: '一年级', value: 1},
  {label: '二年级', value: 2},
  {label: '三年级', value: 3},
  {label: '四年级', value: 4},
  {label: '五年级', value: 5},
  {label: '六年级', value: 6},
  {label: '七年级', value: 7},
  {label: '八年级', value: 8},
  {label: '九年级', value: 9},
]

export const getGradeEnumText = (gradeEnum: GradeEnum) =>
  GradeEnumOptions.find((o) => o.value === gradeEnum)?.label
export interface HomeCollectDTO {
  student?: Student
  statistics?: Statistics
  dailyStatistic?: DailyStatistic
  /** 学校排名 */
  schoolRank?: number
}

export interface LoginVO {
  account?: string
  password?: string
  rememberMe?: boolean
  captcha?: string
  phone?: string
}

export interface PKDTO {
  /** 试题id */
  id?: number
  /** 单词 */
  wordList?: Word[]
  /** 句子 */
  sentenceList?: Sentence[]
}

export interface PKVO {
  /** 得分(句子/单词 得分 , 指法正确率) */
  score: number
  /** PK结果 */
  pkResult?: TrainContentVO[]
}

export interface PreSchoolExam {
  id?: number
  name?: string
  grade?: string
  contractPerson?: string
  contractPhone?: string
  examResult?: string
  paperContent?: string
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
}

/** 学前测试结果 */
export interface PreSchoolExamResultVO {
  /** 测评用时 */
  duration?: number
  /** 单词测评结果 */
  examResult: TrainContentVO[]
}

/** 入学测评学生信息 */
export interface PreSchoolExamVO {
  /** 学生姓名 */
  name: string
  /** 学生年级 */
  grade: string
  /** 联系人姓名 */
  contractPerson: string
  /** 联系人手机号 */
  contractPhone: string
  /** 短信验证码 */
  code: string
}

export interface RegisterResult {}

export interface RegisterVO {
  account?: string
  password?: string
  phone?: string
}

export interface RingChallengeResult {
  champion?: RingChampion
  /** 是否挑战成功 */
  challengeSuccess?: boolean
  challenge?: ChallengeRecord
}

export interface RingChampion {
  id?: number
  challengerId?: number
  challengerName?: string
  ringType?: RingType
  num?: number
  during?: number
  challengeRecordId?: number
  yearAndMonth?: string
  grade?: GradeEnum
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
}

export interface RingChampionSearch {
  ringType?: RingType
  yearAndMonth?: string
  grade?: GradeEnum
}

export enum RingType {
  /** 指法-专注力 */
  ATTENTION = 0,
  /** 指法-记忆力 */
  CONTROL = 1,
  /** 单词 */
  WORD = 2,
  /** 句子 */
  SENTENCE = 3,
  /** 口语 */
  SPEAK = 4,
}

export const RingTypeOptions = [
  {label: '指法-专注力', value: 0},
  {label: '指法-记忆力', value: 1},
  {label: '单词', value: 2},
  {label: '句子', value: 3},
  {label: '口语', value: 4},
]

export const getRingTypeText = (ringType: RingType) =>
  RingTypeOptions.find((o) => o.value === ringType)?.label
export interface Sentence {
  id?: number
  coursewareId?: number
  audioUrl?: string
  cn?: string
  en?: string
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
  delFlag?: number
}

/** 阶段测评 */
export interface StageTestDTO {
  id?: number
  gradeEnum?: GradeEnum
  /** 看翻译写单词 */
  translateWords: Word[]
  /** 听读音写单词 */
  hearingWords: Word[]
  /** 看翻译读单词 */
  readWords: Word[]
  /** 看翻译写句子 */
  translateSentence: Sentence[]
  /** 听语音写句子 */
  hearingSentence: Sentence[]
  /** 看翻译读句子 */
  readSentence: Sentence[]
}

/** 阶段测评结果试题 */
export interface StageTestItemDTO {
  id?: number
  gradeEnum?: GradeEnum
  /** 看翻译写单词 */
  translateWords: Word[]
  /** 听读音写单词 */
  hearingWords: Word[]
  /** 看翻译读单词 */
  readWords: Word[]
  /** 看翻译写句子 */
  translateSentence: Sentence[]
  /** 听语音写句子 */
  hearingSentence: Sentence[]
  /** 看翻译读句子 */
  readSentence: Sentence[]
  /** 测评内容名称 */
  examName: string
  /** 创建时间 */
  createdDate: number
  /** 测评分数 */
  examScore: number
  /** 看翻译写单词错误单词id */
  mistakeTranslateWords: number[]
  /** 听读音写单词错误单词 */
  mistakeHearingWords: number[]
  /** 看翻译读单词错误单词 */
  mistakeReadWords: number[]
  /** 看翻译写句子错误句子 */
  mistakeTranslateSentence: number[]
  /** 听语音写句子错误句子 */
  mistakeHearingSentence: number[]
  /** 看翻译读句子错误句子 */
  mistakeReadSentence: number[]
}

export interface StageTestQueryVO {
  pageIndex?: number
  pageSize?: number
  orderBy?: string
  lastId?: number
}

/** 阶段测评 */
export interface StageTestVO {
  /** 测评分数 */
  examScore: number
  /** 测评用时 */
  duration: number
  /** 看翻译写单词 */
  translateWords: TrainContentVO[]
  /** 听读音写单词 */
  hearingWords: TrainContentVO[]
  /** 看翻译读单词 */
  readWords: TrainContentVO[]
  /** 看翻译写句子 */
  translateSentence: TrainContentVO[]
  /** 听语音写句子 */
  hearingSentence: TrainContentVO[]
  /** 看翻译读句子 */
  readSentence: TrainContentVO[]
}

export interface Statistics {
  id?: number
  beansCount?: number
  consumeBeansCount?: number
  duration?: number
  fastestFingering?: number
  niceCount?: number
  wordCount?: number
  sentenceCount?: number
  examCenterLevel?: ExamLevelCenterEnum
  createdBy?: number
  createdDate?: string
}

export interface Student {
  id?: number
  grade?: GradeEnum
  account?: string
  institutionId?: number
  totalRecharge?: number
  password?: string
  admissionDate?: string
  createdBy?: number
  createdDate?: string
  delFlag?: number
  gender?: GenderEnum
  headImg?: string
  name?: string
  contractPerson?: string
  contractPhone?: string
  school?: string
  studentClass?: string
  updatedBy?: number
  updatedDate?: string
}

/** 学生课件数据 */
export interface StudentCoursewareDTO {
  id?: number
  type?: CoursewareType
  categoryId: number
  sort?: number
  name: string
  price?: number
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
  delFlag?: number
  /** 购买状态 */
  status?: number
  coursewareLevel?: TrainingModeEnum
}

/** 学生信息 */
export interface StudentInfoDTO {
  id?: number
  grade?: GradeEnum
  account?: string
  institutionId?: number
  totalRecharge?: number
  password?: string
  admissionDate?: string
  createdBy?: number
  createdDate?: string
  delFlag?: number
  gender?: GenderEnum
  headImg?: string
  name?: string
  contractPerson?: string
  contractPhone?: string
  school?: string
  studentClass?: string
  updatedBy?: number
  updatedDate?: string
  /** 学校名称 */
  schoolName?: string
  /** 年级名称 */
  gradeName?: string
  /** 班级名称 */
  className?: string
  /** 第一负责人 */
  personCharge?: string
}

export interface StudentToken {
  accessToken?: string
  expiration?: string
  userId?: number
  account?: string
  grade?: GradeEnum
  name?: string
  institutionId?: number
  gender?: GenderEnum
}

export interface SysOption {
  id?: number
  sort?: number
  createdBy?: number
  createdDate?: string
  groupId?: number
  updatedBy?: number
  updatedDate?: string
  text?: string
  value?: string
  desc?: string
}

export interface TestEnumVo {
  trainingMode?: TrainingModeEnum
}

/** 训练内容 */
export interface TrainContentVO {
  /** 课件id */
  coursewareId: number
  /** 内容id(句子id或单词id等) */
  examId: number
  /** 是否错误 */
  mistake: boolean
}

export interface TrainStatistics {
  id?: number
  trainMode?: number
  coursewareId?: number
  rate?: number
  successRate?: number
  successCount?: number
  trainingCount?: number
  failCount?: number
  duration?: number
  niceCount?: number
  createdBy?: number
  createdDate?: string
}

/** 训练记录 */
export interface TrainStatisticsVO {
  /** 训练模式 */
  trainMode: number
  /** 课件id */
  coursewareId?: number
  /** 速率 */
  rate?: number
  /** 成功率 */
  successRate?: number
  /** 成功次数 */
  successCount?: number
  /** 训练次数 */
  trainingCount?: number
  /** 失败次数 */
  failCount?: number
  /** 时长 分 */
  duration?: number
  /** nice数 */
  niceCount?: number
}

export enum TrainingModeEnum {
  /** 指法 */
  FE = 1000,
  /** 基础指法训练一级 */
  FEB_LEVEL_ONE = 1101,
  /** 基础指法训练二级 */
  FEB_LEVEL_TWO = 1102,
  /** 基础指法训练三级 */
  FEB_LEVEL_THREE = 1103,
  /** 基础指法训练四级 */
  FEB_LEVEL_FOUR = 1104,
  /** 基础指法训练五级 */
  FEB_LEVEL_FIVE = 1105,
  /** 基础指法训练六级 */
  FEB_LEVEL_SIX = 1106,
  /** 基础指法训练七级 */
  FEB_LEVEL_SEVEN = 1107,
  /** 高级指法训练一级 */
  FEA_LEVEL_ONE = 1201,
  /** 高级指法训练二级 */
  FEA_LEVEL_TWO = 1202,
  /** 高级指法训练三级 */
  FEA_LEVEL_THREE = 1203,
  /** 指法注意力训练 */
  FEA_WARN = 1301,
  /** 指法控制力记忆力训练 */
  FEC_BASIS = 1401,
  /** 单词 */
  WE = 2000,
  /** 单词训练基础训练 */
  WEB_BASIS = 2101,
  /** 单词训练记忆力训练 */
  WEM_BASIS = 2201,
  /** 单词训练听力手写 */
  WEH_HANDWRITING = 2301,
  /** 单词训练自我测评 */
  WEE_SELF = 2401,
  /** 单词训练阶段复习 */
  WER_STAGE = 2501,
  /** 句子 */
  SE = 3000,
  /** 句子训练基础训练 */
  SEB_LEVEL_ONE = 3101,
  /** 句子训练记忆通关 */
  SEM_BASIS = 3201,
  /** 句子训练背诵训练 */
  SER_BASIS = 3301,
  /** 句子训练默写训练 */
  SEW_SILENT = 3401,
  /** 句子训练阶段复习 */
  SER_STAGE = 3501,
  /** 错题单词练习 */
  WE_WORD = 4101,
  /** 错题句子练习 */
  WE_SENTENCE = 4102,
  /** 测评中心 */
  EXAM_CENTER = 5101,
  /** 挑战擂台 */
  EXAM_RING = 6000,
  /** 挑战擂台指法专注力 */
  EXAM_RING_ATTENTION = 6101,
  /** 挑战擂台指法记忆力 */
  EXAM_RING_CONTROL = 6201,
  /** 挑战擂台单词 */
  EXAM_RING_WORD = 6301,
  /** 挑战擂台句子 */
  EXAM_RING_SENTENCE = 6401,
  /** 挑战擂台口语 */
  EXAM_RING_SPEAK = 6501,
  /** 指法训练 */
  PK_FINGERING = 7101,
  /** 单词训练 */
  PK_WORD = 7201,
  /** 句子训练 */
  PK_SENTENCE = 7301,
}

export const TrainingModeEnumOptions = [
  {label: '指法', value: 1000},
  {label: '基础指法训练一级', value: 1101},
  {label: '基础指法训练二级', value: 1102},
  {label: '基础指法训练三级', value: 1103},
  {label: '基础指法训练四级', value: 1104},
  {label: '基础指法训练五级', value: 1105},
  {label: '基础指法训练六级', value: 1106},
  {label: '基础指法训练七级', value: 1107},
  {label: '高级指法训练一级', value: 1201},
  {label: '高级指法训练二级', value: 1202},
  {label: '高级指法训练三级', value: 1203},
  {label: '指法注意力训练', value: 1301},
  {label: '指法控制力记忆力训练', value: 1401},
  {label: '单词', value: 2000},
  {label: '单词训练基础训练', value: 2101},
  {label: '单词训练记忆力训练', value: 2201},
  {label: '单词训练听力手写', value: 2301},
  {label: '单词训练自我测评', value: 2401},
  {label: '单词训练阶段复习', value: 2501},
  {label: '句子', value: 3000},
  {label: '句子训练基础训练', value: 3101},
  {label: '句子训练记忆通关', value: 3201},
  {label: '句子训练背诵训练', value: 3301},
  {label: '句子训练默写训练', value: 3401},
  {label: '句子训练阶段复习', value: 3501},
  {label: '错题单词练习', value: 4101},
  {label: '错题句子练习', value: 4102},
  {label: '测评中心', value: 5101},
  {label: '挑战擂台', value: 6000},
  {label: '挑战擂台指法专注力', value: 6101},
  {label: '挑战擂台指法记忆力', value: 6201},
  {label: '挑战擂台单词', value: 6301},
  {label: '挑战擂台句子', value: 6401},
  {label: '挑战擂台口语', value: 6501},
  {label: '指法训练', value: 7101},
  {label: '单词训练', value: 7201},
  {label: '句子训练', value: 7301},
]

export const getTrainingModeEnumText = (trainingModeEnum: TrainingModeEnum) =>
  TrainingModeEnumOptions.find((o) => o.value === trainingModeEnum)?.label
export interface TrainingSettings {
  id?: number
  studentId?: number
  /** 朗读次数 */
  readNumber?: number
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
}

/** 升级课件 */
export interface UpgradeCoursewareVO {
  /** 课件id 多个逗号拼接 */
  coursewareIds?: string
  trainMode: TrainingModeEnum
}

export interface VerifyCodeDTO {
  phone: string
  type?: VerifyCodeType
}

export enum VerifyCodeType {
  /** SMS_211492651 */
  PRE_SCHOOL = 1,
  /** SMS_211497560 */
  SUBTRACT_BEANS = 10,
  /** SMS_211492650 */
  RECHARGE_SUCCESS = 11,
}

export const VerifyCodeTypeOptions = [
  {label: 'SMS_211492651', value: 1},
  {label: 'SMS_211497560', value: 10},
  {label: 'SMS_211492650', value: 11},
]

export const getVerifyCodeTypeText = (verifyCodeType: VerifyCodeType) =>
  VerifyCodeTypeOptions.find((o) => o.value === verifyCodeType)?.label
export interface Word {
  id?: number
  coursewareId?: number
  audioUrl?: string
  cn?: string
  en?: string
  ipa?: string
  createdBy?: number
  createdDate?: string
  updatedBy?: number
  updatedDate?: string
  delFlag?: number
}

const trainApi = {
  train: {
    statistics: {
      /**
       *首页汇总数据
       */
      homeCollect(): Promise<HomeCollectDTO> {
        const path = `/api/train/statistics/home-collect`
        return http.post(path).then((res) => res.data.data)
      },
      /**
       *添加一次训练记录
       */
      add(data: TrainStatisticsVO): Promise<TrainStatistics> {
        const path = `/api/train/statistics/add`
        return http.post(path, data).then((res) => res.data.data)
      },
      /**
       *添加训练结果记录
       */
      addExerciseResult(
        trainMode: TrainingModeEnum,
        data: TrainContentVO[],
      ): Promise<boolean> {
        const path = `/api/train/statistics/add-exercise-result`
        return http.post(path, data, {params: {trainMode}}).then((res) => res.data.data)
      },
      testEnum(): Promise<TestEnumVo> {
        const path = `/api/train/statistics/test-enum`
        return http.get(path).then((res) => res.data.data)
      },
      /**
       *获取训练统计总数
       */
      getTotal(): Promise<Statistics> {
        const path = `/api/train/statistics/get-total`
        return http.get(path).then((res) => res.data.data)
      },
      /**
       *获取最小时长
       */
      getMinDuration(trainMode: number): Promise<number> {
        const path = `/api/train/statistics/get-min-duration`
        return http.get(path, {params: {trainMode}}).then((res) => res.data.data)
      },
      /**
       *获取最大nice数
       */
      getMaxNice(trainMode: number): Promise<number> {
        const path = `/api/train/statistics/get-max-nice`
        return http.get(path, {params: {trainMode}}).then((res) => res.data.data)
      },
      /**
       *获取每日训练统计数据
       */
      getDaily(): Promise<DailyStatisticDTO> {
        const path = `/api/train/statistics/get-daily`
        return http.get(path).then((res) => res.data.data)
      },
    },
  },
  students: {
    pb: {
      sendVerifyCode(data: VerifyCodeDTO): Promise<boolean> {
        const path = `/api/students/pb/send-verify-code`
        return http.post(path, data).then((res) => res.data.data)
      },
      register(data: RegisterVO): Promise<RegisterResult> {
        const path = `/api/students/pb/register`
        return http.post(path, data).then((res) => res.data.data)
      },
      login(data: LoginVO): Promise<StudentToken> {
        const path = `/api/students/pb/login`
        return http.post(path, data).then((res) => res.data.data)
      },
      echo(echo: CoursewareVO): Promise<StudentToken> {
        const path = `/api/students/pb/echo`
        return http.get(path, {params: {echo}}).then((res) => res.data.data)
      },
    },
    getUserInfo(): Promise<StudentInfoDTO> {
      const path = `/api/students/get-user-info`
      return http.get(path).then((res) => res.data.data)
    },
    getTrainingSettings(): Promise<TrainingSettings> {
      const path = `/api/students/get-training-settings`
      return http.get(path).then((res) => res.data.data)
    },
  },
  stageTest: {
    /**
     *测评中心提交试卷
     */
    addExamQuestions(id: number, data: StageTestVO): Promise<boolean> {
      const path = `/api/stage-test/add-exam-questions/${id}`
      return http.post(path, data).then((res) => res.data.data)
    },
    /**
     *我的试题
     */
    getPageList(query: StageTestQueryVO): Promise<PageList<StageTestItemDTO>> {
      const path = `/api/stage-test/get-page-list`
      return http.get(path, {params: {query}}).then((res) => res.data.data)
    },
    /**
     *我的试题
     */
    getInfo(id: number): Promise<StageTestItemDTO> {
      const path = `/api/stage-test/get-info/${id}`
      return http.get(path).then((res) => res.data.data)
    },
    /**
     *测评中心生成试卷
     */
    createExamQuestions(params: {
      gradeEnum: GradeEnum
      categoryId: number
    }): Promise<StageTestDTO> {
      const path = `/api/stage-test/create-exam-questions`
      return http.get(path, {params}).then((res) => res.data.data)
    },
  },
  ringChampion: {
    /**
     *更新擂台结果
     */
    updateResult(
      id: number,
      trainMode: TrainingModeEnum,
      data: TrainContentVO[],
    ): Promise<boolean> {
      const path = `/api/ring-champion/update-result/${id}`
      return http.post(path, data, {params: {trainMode}}).then((res) => res.data.data)
    },
    /**
     *开始挑战擂主
     */
    startChallenge(params: {
      gradeEnum: GradeEnum
      ringType: RingType
    }): Promise<ChallengeRecord> {
      const path = `/api/ring-champion/start-challenge`
      return http.post(path, null, {params}).then((res) => res.data.data)
    },
    /**
     *获取当前擂主
     */
    getChampion(data: RingChampionSearch): Promise<RingChampion> {
      const path = `/api/ring-champion/get-champion`
      return http.post(path, data).then((res) => res.data.data)
    },
    getChallengeRecordList(
      data: ChallengeRecordSearch,
    ): Promise<PageList<ChallengeRecordDTO>> {
      const path = `/api/ring-champion/get-challenge-record-list`
      return http.post(path, data).then((res) => res.data.data)
    },
    createWordQuestions(gradeEnum: GradeEnum): Promise<ExamQuestionsDTO> {
      const path = `/api/ring-champion/create-word-questions`
      return http.post(path, null, {params: {gradeEnum}}).then((res) => res.data.data)
    },
    createSpeakQuestions(gradeEnum: GradeEnum): Promise<ExamQuestionsDTO> {
      const path = `/api/ring-champion/create-speak-questions`
      return http.post(path, null, {params: {gradeEnum}}).then((res) => res.data.data)
    },
    createSentenceQuestions(gradeEnum: GradeEnum): Promise<ExamQuestionsDTO> {
      const path = `/api/ring-champion/create-sentence-questions`
      return http.post(path, null, {params: {gradeEnum}}).then((res) => res.data.data)
    },
    /**
     *挑战擂主，根据返回数据里challengeSuccess判断是否挑战成功
     */
    challenge(data: ChallengeRecord): Promise<RingChallengeResult> {
      const path = `/api/ring-champion/challenge`
      return http.post(path, data).then((res) => res.data.data)
    },
  },
  pk: {
    /**
     *更新pk结果
     */
    updateExamResult(id: number, data: PKVO): Promise<boolean> {
      const path = `/api/pk/update-exam-result/${id}`
      return http.post(path, data).then((res) => res.data.data)
    },
    /**
     *获取pk试题
     */
    getExamSource(id: number): Promise<PKDTO> {
      const path = `/api/pk/get-exam-source/${id}`
      return http.get(path).then((res) => res.data.data)
    },
    /**
     *创建一次pk试题
     */
    createExamSource(params: {
      gradeEnum: GradeEnum
      trainMode: TrainingModeEnum
    }): Promise<PKDTO> {
      const path = `/api/pk/create-exam-source`
      return http.get(path, {params}).then((res) => res.data.data)
    },
  },
  exam: {
    pre: {
      pb: {
        /**
         *记录学生学前测评数据
         */
        result(id: number, data: PreSchoolExamResultVO): Promise<PreSchoolExam> {
          const path = `/api/exam/pre/pb/result/${id}`
          return http.post(path, data).then((res) => res.data.data)
        },
        /**
         *添加学前测试学生信息
         */
        add(data: PreSchoolExamVO): Promise<PreSchoolExam> {
          const path = `/api/exam/pre/pb/add`
          return http.post(path, data).then((res) => res.data.data)
        },
        /**
         *获得学前测评数据
         */
        list(id: number): Promise<Word[]> {
          const path = `/api/exam/pre/pb/list/${id}`
          return http.get(path).then((res) => res.data.data)
        },
      },
    },
    level: {
      center: {
        /**
         *获取考级中心习题
         */
        addResult(examId: number, data: ExamLevelCenterVO): Promise<boolean> {
          const path = `/api/exam/level/center/add-result/${examId}`
          return http.post(path, data).then((res) => res.data.data)
        },
        /**
         *获取考级中心习题
         */
        getPaper(examLevel: ExamLevelCenterEnum): Promise<ExamLevelCenterDTO> {
          const path = `/api/exam/level/center/get-paper`
          return http.get(path, {params: {examLevel}}).then((res) => res.data.data)
        },
      },
    },
  },
  courseware: {
    /**
     *升级课件
     */
    upgrade(data: UpgradeCoursewareVO): Promise<boolean> {
      const path = `/api/courseware/upgrade`
      return http.post(path, data).then((res) => res.data.data)
    },
    /**
     *保存当前选择的课件
     */
    saveChooseCoursewares(type: CoursewareType, data: number[]): Promise<boolean> {
      const path = `/api/courseware/save-choose-coursewares`
      return http.post(path, data, {params: {type}}).then((res) => res.data.data)
    },
    /**
     *通过分类id,年级id,课件状态,课件类型,获取课件列表
     */
    getList(data: CoursewareVO): Promise<StudentCoursewareDTO[]> {
      const path = `/api/courseware/get-list`
      return http.post(path, data).then((res) => res.data.data)
    },
    /**
     *获取当前选择的课件
     */
    getChooseCoursewares(type: CoursewareType): Promise<number[]> {
      const path = `/api/courseware/get-choose-coursewares`
      return http.post(path, null, {params: {type}}).then((res) => res.data.data)
    },
    category: {
      /**
       *获得课件分类
       */
      getList(data: CoursewareCategoryVO): Promise<CoursewareCategoryTree[]> {
        const path = `/api/courseware/category/get-list`
        return http.post(path, data).then((res) => res.data.data)
      },
    },
    recycle: {
      /**
       *通过课件类型获取回收课件列表
       */
      get(coursewareIds: string): Promise<boolean> {
        const path = `/api/courseware/recycle`
        return http.get(path, {params: {coursewareIds}}).then((res) => res.data.data)
      },
      /**
       *通过课件类型获取回收课件列表
       */
      getList(type: CoursewareType): Promise<CoursewareDTO[]> {
        const path = `/api/courseware/recycle/get-list`
        return http.get(path, {params: {type}}).then((res) => res.data.data)
      },
    },
    /**
     *获取课件最低等级
     */
    getLowestLevel(coursewareIds: string): Promise<number> {
      const path = `/api/courseware/get-lowest-level`
      return http.get(path, {params: {coursewareIds}}).then((res) => res.data.data)
    },
    /**
     *获得已购买课件
     */
    getBuyCoursewares(type: CoursewareType): Promise<CategoryAndCoursewareDTO[]> {
      const path = `/api/courseware/get-buy-coursewares`
      return http.get(path, {params: {type}}).then((res) => res.data.data)
    },
    /**
     *通过课件id购买课件
     */
    buy(coursewareIds: string): Promise<Courseware[]> {
      const path = `/api/courseware/buy`
      return http.get(path, {params: {coursewareIds}}).then((res) => res.data.data)
    },
  },
  beans: {
    consume: {
      /**
       *训练一分钟扣豆子
       */
      add(data: BeansConsumeVO): Promise<BeansWater> {
        const path = `/api/beans/consume/add`
        return http.post(path, data).then((res) => res.data.data)
      },
    },
  },
  word: {
    /**
     *获取阶段复习单词列表
     */
    reviewList(): Promise<Word[]> {
      const path = `/api/word/review-list`
      return http.get(path).then((res) => res.data.data)
    },
    /**
     *根据课件id获取单词列表
     */
    list(coursewareIds: string): Promise<Word[]> {
      const path = `/api/word/list`
      return http.get(path, {params: {coursewareIds}}).then((res) => res.data.data)
    },
  },
  sysOptions: {
    getOptions(groupCode: string): Promise<SysOption[]> {
      const path = `/api/sys-options/get-options`
      return http.get(path, {params: {groupCode}}).then((res) => res.data.data)
    },
    /**
     *获取年级数据
     */
    getGrades(): Promise<SysOption[]> {
      const path = `/api/sys-options/get-grades`
      return http.get(path).then((res) => res.data.data)
    },
  },
  sentence: {
    /**
     *获取阶段复习句子列表
     */
    reviewList(): Promise<Sentence[]> {
      const path = `/api/sentence/review-list`
      return http.get(path).then((res) => res.data.data)
    },
    /**
     *通过课件id获取句子列表
     */
    list(coursewareIds: string): Promise<Sentence[]> {
      const path = `/api/sentence/list`
      return http.get(path, {params: {coursewareIds}}).then((res) => res.data.data)
    },
  },
  mistake: {
    history: {
      dailyData: {
        /**
         *获得今日错题训练数据
         */
        list(type: CoursewareType): Promise<DailyExamMistakeDTO[]> {
          const path = `/api/mistake/history/daily-data/list`
          return http.get(path, {params: {type}}).then((res) => res.data.data)
        },
      },
    },
  },
  file: {
    download(params: {filePath: string; fileName: string}): Promise<string> {
      const path = `/api/file/download`
      return http.get(path, {params}).then((res) => res.data.data)
    },
  },
}
export default trainApi
