import React, {useCallback, useEffect, useMemo, useState} from 'react'
import adminApi, {
  TrainingSettings,
  GenderEnumOptions,
  Institution,
  Student,
  GradeEnumOptions,
  VerifyCodeType,
} from '@/api/admin'
import {ApiTable, Form, FormItem, Loading, overlay} from '@/components'
import {useAuth} from '@/services/auth'
import ModalForm from '@/components/modal/modal-form'
import {useHistory} from 'react-router-dom'
import trainApi, {GradeEnum, RingType} from '@/api/train'
const studentLables = {
  name: '学生姓名',
  beansCount: '豆子数',
  account: '学生账号',
  contractPerson: '联系人',
  contractPhone: '联系电话',
  gender: '性别',
  admissionDate: '入学时间',
  password: '登录密码',
  grade: '年级',
  update: '修改学生信息',
  create: '新增学生信息',
  readNumber: '朗读单词数',
  school: '学校',
  studentClass: '班级',
}
const TrainingSettingsEdit: ModalComponent<Institution> = (props) => {
  const [traingSettings, setTrainingSettings] = useState<TrainingSettings>({
    studentId: props.model.id,
  })
  const fetchData = useCallback(async () => {
    let data = await adminApi.students.getTrainingSettings(props.model.id)
    setTrainingSettings((prev) => {
      return {...prev, ...data}
    })
  }, [props.model.id])

  return (
    <Loading loadData={fetchData}>
      <Form
        labels={studentLables}
        initialValues={{name: props.model.name, ...traingSettings}}
        api={adminApi.students.saveTrainingSettings}>
        <FormItem.Input name="name" readOnly />
        <FormItem.Input type="number" name="readNumber" />
      </Form>
    </Loading>
  )
}
TrainingSettingsEdit.defaultModalProps = (props) => {
  return {title: '训练设置'}
}
const InstitutionEdit: ModalComponent<Institution> = (props) => {
  const isEdit = !!props.model.id
  const [auth] = useAuth()
  return (
    <Form
      labels={studentLables}
      initialValues={{...props.model, institutionId: auth.institutionId}}
      api={props.model?.id ? adminApi.students.update : adminApi.students.create}>
      <FormItem.Input name="name" required />
      <FormItem.Input name="account" required />
      <FormItem.Input name="password" required hidden={isEdit} />
      <FormItem.Select name="grade" required options={GradeEnumOptions} />
      <FormItem.RadioGroup name="gender" required options={GenderEnumOptions} />
      <FormItem.Input name="contractPerson" required />
      <FormItem.Input name="contractPhone" required />
      <FormItem.DatePicker name="admissionDate" required />
      <FormItem.Input name="school" required />
      <FormItem.Input name="studentClass" required />
    </Form>
  )
}
InstitutionEdit.defaultModalProps = (props) => {
  return {
    title: props.model?.id ? studentLables.update : studentLables.create,
    width: 'large',
  }
}
const InstitutionPage: PageComponent = () => {
  const history = useHistory()

  const deleteRole = useCallback(async (data: Institution) => {
    if (await overlay.deleteConfirm()) {
      return adminApi.institutions.delete(data)
    }
  }, [])

  const onSubtractBeans = useCallback(async (data: Student) => {
    return ModalForm.confirm({
      title: `给${data.name}扣减豆子`,
      modal: {studentId: data.id, count: 0},
      config: {
        initialValues: {studentId: data.id, count: 0},
        labels: {count: '扣减豆子数', verifyCode: '验证码', reason: '扣减原因'},
        labelCol: {span: 6},
        wrapperCol: {span: 18},
        api: adminApi.beans.subtract.student,
      },
      items: [
        {
          type: 'inputNumber',
          config: {
            type: 'number',
            name: 'count',
            required: true,
            placeholder: '请输入需要扣减的豆子数',
            max: 10000,
          },
        },
        {
          type: 'verifyCode',
          config: {
            type: 'string',
            name: 'verifyCode',
            required: true,
            placeholder: '验证码',
            maxLength: 6,
            mobile: '13545903824',
            codeType: VerifyCodeType.SUBTRACT_BEANS,
          },
        },
        {
          type: 'textArea',
          config: {
            type: 'string',
            name: 'reason',
            required: true,
            placeholder: '请输入需要扣减原因',
            maxLength: 100,
          },
        },
      ],
    })
  }, [])

  const onRechargeBeans = useCallback(async (data: Student) => {
    return ModalForm.confirm({
      title: `给${data.name}充值豆子`,
      modal: {studentId: data.id, count: 0},
      config: {
        initialValues: {studentId: data.id, count: 0},
        labels: {count: '充值豆子数'},
        labelCol: {span: 6},
        wrapperCol: {span: 18},
        api: adminApi.beans.recharge.student,
      },
      items: [
        {
          type: 'inputNumber',
          config: {
            type: 'number',
            name: 'count',
            required: true,
            placeholder: '请输入需要充值的豆子数',
            max: 100000,
          },
        },
      ],
    })
  }, [])
  const [auth] = useAuth()
  const data: Student = useMemo(() => {
    return {institutionId: auth.institutionId}
  }, [auth])
  return (
    <ApiTable
      labels={studentLables}
      api={adminApi.students.getPageList}
      data={data}
      tableTitle="学生信息列表"
      toolBar={[
        {
          title: studentLables.create,
          component: InstitutionEdit,
          buttonType: 'create',
        },
      ]}
      columns={[
        {valueType: 'index'},
        {dataIndex: 'name', operator: 'like'},
        {dataIndex: 'account', operator: 'like'},
        {dataIndex: 'grade', options: GradeEnumOptions, operator: 'eq'},
        {dataIndex: 'gender', options: GenderEnumOptions},
        {dataIndex: 'admissionDate', valueType: 'date'},
        {dataIndex: 'contractPerson'},
        {dataIndex: 'contractPhone'},
        {dataIndex: 'beansCount', valueType: 'number'},
        {
          actions: [
            {
              component: InstitutionEdit,
              title: '编辑',
            },
            {
              component: TrainingSettingsEdit,
              title: '训练设置',
            },
            {
              onClick: onRechargeBeans,
              title: '充值豆子',
            },
            {
              onClick: onSubtractBeans,
              title: '扣减豆子',
            },
            {
              onClick: deleteRole,
              title: '删除',
            },
          ],
        },
      ]}
    />
  )
}
InstitutionPage.options = {name: '机构管理', path: '/institution/manage'}
export default InstitutionPage
