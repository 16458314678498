import axios, {AxiosResponse} from 'axios'
import {getAuth, logout} from './auth'
interface ApiError {
  code: number
  msg: string
}
export function isApiError(
  ex: AxiosResponse<ApiError> | any,
): ex is AxiosResponse<ApiError> {
  return ex && ex.config && ex.data && ex.data.code
}
const httpInit = axios.create({
  timeout: 1000 * 180,
  baseURL: globalThis.apiBaseUrl,
})
httpInit.interceptors.request.use(async (config) => {
  const auth = getAuth()
  if (auth.isLogin) {
    config.headers.Authorization = 'Bearer ' + auth.accessToken
    config.headers.userId = auth.userId
    config.headers.institutionId = auth.institutionId
  }
  return config
})
httpInit.interceptors.response.use(
  (res) => {
    const body = res.data
    if (body.code === 405 || body.code === 403) {
      logout()
      // store.dispatch({ type: "SIGN_OUT" })
      throw res
    } else if (parseInt(body.code, 10) !== 200) {
      console.warn('error', res)

      return Promise.reject(res)
    }
    return res
  },
  (error) => {
    // message.error('系统出错，请联系管理员')
    console.warn('http error', error, error.message)

    return Promise.reject(error)
  },
)
Object.defineProperty(globalThis, 'http', {
  get() {
    return httpInit
  },
})

export default httpInit
