import React, {useMemo} from 'react'
import ProtectedLayout from './ProtectedLayout'
import zhCN from 'antd/lib/locale/zh_CN'
import {ConfigProvider} from 'antd'
import moment from 'moment'
import {BrowserRouter, HashRouter, Route, Switch} from 'react-router-dom'
import 'moment/locale/zh-cn'
import {AppConfig, AppConfigProvider} from './appConfig'
import withTitle from './withTitle'
moment.locale('zh-cn')
const AppContainer: React.FC<AppConfig> = (props) => {
  const [publicRoutes, protectRoutes] = useMemo(() => {
    let publicRoutes: JSX.Element[] = []
    let protectRoutes: JSX.Element[] = []
    for (let path in props.routes) {
      let Component = withTitle(props.routes[path])
      if (Component.options?.layout === 'public') {
        publicRoutes.push(<Route exact key={path} path={path} component={Component} />)
      } else {
        protectRoutes.push(<Route key={path} path={path} component={Component} />)
      }
    }

    return [publicRoutes, protectRoutes]
  }, [props.routes])

  return (
    <AppConfigProvider {...props}>
      <ConfigProvider locale={zhCN}>
        <HashRouter>
          <Switch>
            {publicRoutes}
            <ProtectedLayout routes={protectRoutes} menus={props.menus} />
          </Switch>
        </HashRouter>
      </ConfigProvider>
    </AppConfigProvider>
  )
}
export default AppContainer
