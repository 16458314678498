import {importExcel} from '@/utils/excelUtils'
import React from 'react'
import {getOptionText} from '../select'
import {useTable} from './ApiTable'
import {DataIndex} from 'rc-table/lib/interface'
interface Props {
  value: any
  dataIndex: DataIndex
}
const OptionCell: React.FC<Props> = (props) => {
  let table = useTable()

  return <>{getOptionText(props.value, table.options[props.dataIndex?.toString()])}</>
}
export default OptionCell
