import React, {useEffect} from 'react'
import adminApi, {BeansTypeEnum, BeansTypeEnumOptions, GradeEnum} from '@/api/admin'
import {ApiTable} from '@/components'

const labels = {
  institutionName: '机构姓名',
  operatorType: '变更类型',
  beansCount: '变更数',
  businessContent: '消费内容',
  createdDate: '消费时间',
}

const InstitutionWaterFlowPage: PageComponent = (props) => {
  return (
    <ApiTable
      data={{
        operatorType: {
          $in: [BeansTypeEnum.STUDENT_RECHARGE_INS, BeansTypeEnum.INSTITUTION_RECHARGE],
        },
      }}
      api={adminApi.beans.getInstitutionPageList}
      labels={labels}
      tableTitle="机构流水记录列表"
      columns={[
        {valueType: 'index'},
        {dataIndex: 'institutionName', operator: 'like'},
        {dataIndex: 'operatorType', options: BeansTypeEnumOptions},
        {
          dataIndex: 'beansCount',
        },
        {dataIndex: 'createdDate', valueType: 'date'},
      ]}
    />
  )
}

InstitutionWaterFlowPage.options = {
  name: '豆子流水',
  path: '/institution/institution-water-flow',
}
export default InstitutionWaterFlowPage
