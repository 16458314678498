export interface Audit {
	id?: number
	createBy?: string
	ip?: string
	resourceId?: number
	url?: string
	userAgent?: string
	userId?: string
}

export interface AuthTokenDTO {
	accessToken?: string
	isSuperAdmin?: boolean
	expiration?: string
	refreshToken?: string
	userId?: number
	account?: string
	name?: string
	institutionId?: number
	permissions?: string[]
}

export interface BasicResult {
	explains?: string[]
	'uk-phonetic'?: string
	'uk-speech'?: string
}

export enum BeansTypeEnum {
	/** 训练消费 */
	TRAIN = 1,
	/** 购买课件 */
	BUY_COURSEWARE = 2,
	/** 管理后台机构扣除 */
	ORGANIZATION = 3,
	/** 错题扣除 */
	MISTAKE = 4,
	/** 挑战擂主 */
	RING_CHAMPION = 5,
	/** 机构管理后台扣减豆子 */
	INSTITUTION_SUBTRACT_STUDENT = 8,
	/** 结构给学生充值增加学生豆子数 */
	STUDENT_RECHARGE_STU = 9,
	/** 结构给学生充值扣减机构豆子数 */
	STUDENT_RECHARGE_INS = 10,
	/** 错题退款 */
	RECHARGE_MISTAKE = 11,
	/** 课件退款 */
	RECHARGE_COURSEWARE = 12,
	/** 挑战擂主成功奖励 */
	CHAMPION_REWARD = 13,
	/** 机构充值 */
	INSTITUTION_RECHARGE = 14,
}

export const BeansTypeEnumOptions = [
	{ label: '训练消费', value: 1 },
	{ label: '购买课件', value: 2 },
	{ label: '管理后台机构扣除', value: 3 },
	{ label: '错题扣除', value: 4 },
	{ label: '挑战擂主', value: 5 },
	{ label: '机构管理后台扣减豆子', value: 8 },
	{ label: '结构给学生充值增加学生豆子数', value: 9 },
	{ label: '结构给学生充值扣减机构豆子数', value: 10 },
	{ label: '错题退款', value: 11 },
	{ label: '课件退款', value: 12 },
	{ label: '挑战擂主成功奖励', value: 13 },
	{ label: '机构充值', value: 14 },
]

export const getBeansTypeEnumText = (beansTypeEnum: BeansTypeEnum) =>
	BeansTypeEnumOptions.find((o) => o.value === beansTypeEnum)?.label
/** 豆子流水记录 */
export interface BeansWater {
	id?: number
	beansCount?: number
	effectId?: number
	institutionId?: number
	operatorType?: BeansTypeEnum
	businessId?: number
	businessContent?: string
	createdBy?: number
	createdDate?: string
}

export interface BeansWaterDTO {
	id?: number
	beansCount?: number
	effectId?: number
	institutionId?: number
	operatorType?: BeansTypeEnum
	businessId?: number
	businessContent?: string
	createdBy?: number
	createdDate?: string
	studentName?: string
	institutionName?: string
}

export interface Courseware {
	id?: number
	type?: CoursewareType
	categoryId: number
	sort?: number
	name: string
	price?: number
	createdBy?: number
	createdDate?: string
	updatedBy?: number
	updatedDate?: string
	delFlag?: number
}

export interface CoursewareCategory {
	id?: number
	parentId?: number
	treePath?: string
	sort?: number
	grade?: GradeEnum
	fullName?: string
	name?: string
	delFlag?: number
	updatedBy?: number
	updatedDate?: string
	createdBy?: number
	createdDate?: string
}

export enum CoursewareType {
	/** 单词课件 */
	WORD = 1,
	/** 句子课件 */
	SENTENCE = 2,
}

export const CoursewareTypeOptions = [
	{ label: '单词课件', value: 1 },
	{ label: '句子课件', value: 2 },
]

export const getCoursewareTypeText = (coursewareType: CoursewareType) =>
	CoursewareTypeOptions.find((o) => o.value === coursewareType)?.label
export enum GenderEnum {
	/** 男 */
	MALE = 0,
	/** 女 */
	FEMALE = 1,
}

export const GenderEnumOptions = [
	{ label: '男', value: 0 },
	{ label: '女', value: 1 },
]

export const getGenderEnumText = (genderEnum: GenderEnum) =>
	GenderEnumOptions.find((o) => o.value === genderEnum)?.label
export enum GradeEnum {
	/** 一年级 */
	FIRST_GRADE = 1,
	/** 二年级 */
	SECOND_GRADE = 2,
	/** 三年级 */
	THIRD_GRADE = 3,
	/** 四年级 */
	FOURTH_GRADE = 4,
	/** 五年级 */
	FIFTH_GRADE = 5,
	/** 六年级 */
	SIXTH_GRADE = 6,
	/** 七年级 */
	SEVENTH_GRADE = 7,
	/** 八年级 */
	EIGHTH_GRADE = 8,
	/** 九年级 */
	NINTH_GRADE = 9,
}

export const GradeEnumOptions = [
	{ label: '一年级', value: 1 },
	{ label: '二年级', value: 2 },
	{ label: '三年级', value: 3 },
	{ label: '四年级', value: 4 },
	{ label: '五年级', value: 5 },
	{ label: '六年级', value: 6 },
	{ label: '七年级', value: 7 },
	{ label: '八年级', value: 8 },
	{ label: '九年级', value: 9 },
]

export const getGradeEnumText = (gradeEnum: GradeEnum) =>
	GradeEnumOptions.find((o) => o.value === gradeEnum)?.label
export interface Institution {
	id?: number
	name?: string
	userId?: number
	updatedBy?: number
	updatedDate?: string
	createdBy?: number
	createdDate?: string
	delFlag?: number
}

export interface InstitutionDTO {
	id?: number
	name?: string
	userId?: number
	updatedBy?: number
	updatedDate?: string
	createdBy?: number
	createdDate?: string
	delFlag?: number
	contractPerson?: string
	contractAccount?: string
	contractPassword?: string
	contractNumber?: string
	beanNumbers?: number
	totalRecharge?: number
}

export interface LoginVO {
	account?: string
	password?: string
	rememberMe?: boolean
	captcha?: string
	phone?: string
}

/** 系统组织表 */
export interface Organ {
	id?: number
	address?: string
	createdBy?: number
	createdDate?: string
	delFlag?: number
	icon?: string
	name?: string
	parentId?: number
	phone?: string
	sort?: number
	treePath?: string
	type?: number
	updatedBy?: number
	updatedDate?: string
}

export interface RegisterResult { }

export interface RegisterVO {
	account?: string
	password?: string
	phone?: string
}

/** 系统资源 */
export interface Resource {
	id?: number
	actionCode?: string
	createdBy?: number
	createdDate?: string
	delFlag?: number
	icon?: string
	name?: string
	parentId?: number
	path?: string
	sort?: number
	treePath?: string
	type?: ResourceType
	updatedBy?: number
	updatedDate?: string
}

export enum ResourceType {
	/** 系统 */
	SYSTEM = 0,
	/** 菜单组 */
	MENU_GROUP = 1,
	/** 菜单 */
	MENU = 2,
	/** 动作点 */
	ACTION = 3,
}

export const ResourceTypeOptions = [
	{ label: '系统', value: 0 },
	{ label: '菜单组', value: 1 },
	{ label: '菜单', value: 2 },
	{ label: '动作点', value: 3 },
]

export const getResourceTypeText = (resourceType: ResourceType) =>
	ResourceTypeOptions.find((o) => o.value === resourceType)?.label
export interface RestPasswordVO {
	userId?: number
	newPassword?: string
}

/** 系统角色 */
export interface Role {
	id?: number
	systemRole?: boolean
	createdBy?: number
	createdDate?: string
	delFlag?: number
	name?: string
	remarks?: string
	updatedBy?: number
	updatedDate?: string
}

export interface Sentence {
	id?: number
	coursewareId?: number
	audioUrl?: string
	cn?: string
	en?: string
	createdBy?: number
	createdDate?: string
	updatedBy?: number
	updatedDate?: string
	delFlag?: number
}

/** 单词数据 */
export interface SentenceDTO {
	id?: number
	coursewareId?: number
	/** 音频链接 */
	audioUrl?: string
	cn?: string
	en?: string
	createdBy?: number
	createdDate?: string
	updatedBy?: number
	updatedDate?: string
	delFlag?: number
	/** 创建人名称 */
	createdByName?: string
	/** 更新人名称 */
	updatedByName?: string
}

export interface Student {
	id?: number
	grade?: GradeEnum
	account?: string
	institutionId?: number
	totalRecharge?: number
	password?: string
	admissionDate?: string
	createdBy?: number
	createdDate?: string
	delFlag?: number
	gender?: GenderEnum
	headImg?: string
	name?: string
	contractPerson?: string
	contractPhone?: string
	school?: string
	studentClass?: string
	updatedBy?: number
	updatedDate?: string
}

/** 学生基础信息 */
export interface StudentDTO {
	id?: number
	grade?: GradeEnum
	account?: string
	institutionId?: number
	totalRecharge?: number
	password?: string
	admissionDate?: string
	createdBy?: number
	createdDate?: string
	delFlag?: number
	gender?: GenderEnum
	headImg?: string
	name?: string
	contractPerson?: string
	contractPhone?: string
	school?: string
	studentClass?: string
	updatedBy?: number
	updatedDate?: string
	/** 学生豆子数 */
	beansCount?: number
}

export interface SubtractBeansVO {
	studentId?: number
	count?: number
	verifyCode?: string
	reason?: string
}

export interface SysOption {
	id?: number
	sort?: number
	createdBy?: number
	createdDate?: string
	groupId?: number
	updatedBy?: number
	updatedDate?: string
	text?: string
	value?: string
	desc?: string
}

export interface SysOptionGroup {
	id?: number
	name?: string
	code?: string
	createdBy?: number
	createdDate?: string
	updatedBy?: number
	updatedDate?: string
}

export interface SysParam {
	id?: number
	/** 每日最多挑战次数 */
	maxChallengeNum?: number
	/** 最大欠费豆子数,当达到该值后将退出登录 */
	maxDueBean?: number
}

export interface TrainingSettings {
	id?: number
	studentId?: number
	/** 朗读次数 */
	readNumber?: number
	createdBy?: number
	createdDate?: string
	updatedBy?: number
	updatedDate?: string
}

export interface TranslateRequest {
	en?: string
}

export interface TranslateResult {
	errorCode?: string
	query?: string
	basic?: BasicResult
	web?: WebResult[]
	translation?: string[]
	isWord?: boolean
	speakUrl?: string
	audioUrl?: string
}

/** 系统角色 */
export interface User {
	id?: number
	institutionId?: number
	account?: string
	createdBy?: number
	createdDate?: string
	delFlag?: number
	email?: string
	gender?: number
	headImg?: string
	isSuperAdmin?: boolean
	name?: string
	phone?: string
	status?: UserStatus
	updatedBy?: number
	updatedDate?: string
}

export enum UserStatus {
	/** 正常 */
	NORMAL = 0,
	/** 禁用 */
	DISABLED = 1,
	/** 未激活 */
	UNACTIVATED = 3,
}

export const UserStatusOptions = [
	{ label: '正常', value: 0 },
	{ label: '禁用', value: 1 },
	{ label: '未激活', value: 3 },
]

export const getUserStatusText = (userStatus: UserStatus) =>
	UserStatusOptions.find((o) => o.value === userStatus)?.label
export interface VerifyCodeDTO {
	phone: string
	type?: VerifyCodeType
}

export enum VerifyCodeType {
	/** SMS_211492651 */
	PRE_SCHOOL = 1,
	/** SMS_211497560 */
	SUBTRACT_BEANS = 10,
	/** SMS_211492650 */
	RECHARGE_SUCCESS = 11,
}

export const VerifyCodeTypeOptions = [
	{ label: 'SMS_211492651', value: 1 },
	{ label: 'SMS_211497560', value: 10 },
	{ label: 'SMS_211492650', value: 11 },
]

export const getVerifyCodeTypeText = (verifyCodeType: VerifyCodeType) =>
	VerifyCodeTypeOptions.find((o) => o.value === verifyCodeType)?.label
export interface WebResult {
	key?: string
	value?: string[]
}

export interface Word {
	id?: number
	coursewareId?: number
	audioUrl?: string
	cn?: string
	en?: string
	ipa?: string
	createdBy?: number
	createdDate?: string
	updatedBy?: number
	updatedDate?: string
	delFlag?: number
}

/** 单词数据 */
export interface WordDTO {
	id?: number
	coursewareId?: number
	/** 音频链接 */
	audioUrl?: string
	cn?: string
	en?: string
	ipa?: string
	createdBy?: number
	createdDate?: string
	updatedBy?: number
	updatedDate?: string
	delFlag?: number
	/** 创建人名称 */
	createdByName?: string
	/** 更新人名称 */
	updatedByName?: string
}

const adminApi = {
	word: {
		translate(data: TranslateRequest): Promise<TranslateResult> {
			const path = `/api/admin/word/translate`
			return http.post(path, data).then((res) => res.data.data)
		},
		save(data: Word): Promise<Word> {
			const path = `/api/admin/word/save`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *查询单词列表 - 分页查询
		 */
		getPageList(data: QueryMap<Word>): Promise<PageList<WordDTO>> {
			const path = `/api/admin/word/get-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *删除单词
		 */
		delete(id: number): Promise<boolean> {
			const path = `/api/admin/word/delete`
			return http.post(path, null, { params: { id } }).then((res) => res.data.data)
		},
		/**
		 *根据id查询单个单词信息
		 */
		get(id: number): Promise<Word> {
			const path = `/api/admin/word/get`
			return http.get(path, { params: { id } }).then((res) => res.data.data)
		},
	},
	users: {
		/**
		 *修改
		 */
		update(data: User): Promise<User> {
			const path = `/api/admin/users/update`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *重置密码
		 */
		resetPassword(data: RestPasswordVO): Promise<number> {
			const path = `/api/admin/users/reset-password`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *获取用户的角色id
		 */
		getUserRoleIds(data: number): Promise<number[]> {
			const path = `/api/admin/users/get-user-roleIds`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *分页查询
		 */
		getPageList(data: QueryMap<User>): Promise<PageList<User>> {
			const path = `/api/admin/users/get-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *用户启用
		 */
		enable(data: number): Promise<number> {
			const path = `/api/admin/users/enable`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *用户禁用
		 */
		disable(data: number): Promise<number> {
			const path = `/api/admin/users/disable`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *删除单条
		 */
		delete(data: User): Promise<boolean> {
			const path = `/api/admin/users/delete`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *新增
		 */
		create(data: User): Promise<User> {
			const path = `/api/admin/users/create`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *用户赋予角色
		 */
		authorizeRoles(userId: number, data: number[]): Promise<number> {
			const path = `/api/admin/users/authorize-roles`
			return http.post(path, data, { params: { userId } }).then((res) => res.data.data)
		},
		/**
		 *根据主键获取单条
		 */
		get(id: number): Promise<User> {
			const path = `/api/admin/users/${id}`
			return http.get(path).then((res) => res.data.data)
		},
	},
	sysParams: {
		/**
		 *修改
		 */
		save(data: SysParam): Promise<SysParam> {
			const path = `/api/admin/sys-params/save`
			return http.post(path, data).then((res) => res.data.data)
		},
		get(): Promise<SysParam> {
			const path = `/api/admin/sys-params`
			return http.get(path).then((res) => res.data.data)
		},
	},
	sysOptions: {
		/**
		 *修改
		 */
		update(data: SysOption): Promise<SysOption> {
			const path = `/api/admin/sys-options/update`
			return http.post(path, data).then((res) => res.data.data)
		},
		saveGroup(data: SysOptionGroup): Promise<SysOptionGroup> {
			const path = `/api/admin/sys-options/save-group`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *分页查询
		 */
		getPageList(data: QueryMap<SysOption>): Promise<PageList<SysOption>> {
			const path = `/api/admin/sys-options/get-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *删除单条
		 */
		delete(data: SysOption): Promise<boolean> {
			const path = `/api/admin/sys-options/delete`
			return http.post(path, data).then((res) => res.data.data)
		},
		deleteGroup(data: number): Promise<boolean> {
			const path = `/api/admin/sys-options/delete-group`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *新增
		 */
		create(data: SysOption): Promise<SysOption> {
			const path = `/api/admin/sys-options/create`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *根据主键获取单条
		 */
		get(id: number): Promise<SysOption> {
			const path = `/api/admin/sys-options/${id}`
			return http.get(path).then((res) => res.data.data)
		},
		getOptions(groupId: number): Promise<SysOption[]> {
			const path = `/api/admin/sys-options/get-options`
			return http.get(path, { params: { groupId } }).then((res) => res.data.data)
		},
		getOptionsByCode(code: string): Promise<SysOption[]> {
			const path = `/api/admin/sys-options/get-options-by-code`
			return http.get(path, { params: { code } }).then((res) => res.data.data)
		},
		getAllGroups(): Promise<SysOptionGroup[]> {
			const path = `/api/admin/sys-options/get-all-groups`
			return http.get(path).then((res) => res.data.data)
		},
	},
	students: {
		/**
		 *修改
		 */
		update(data: Student): Promise<Student> {
			const path = `/api/admin/students/update`
			return http.post(path, data).then((res) => res.data.data)
		},
		sendVerifyCode(data: VerifyCodeDTO): Promise<boolean> {
			const path = `/api/admin/students/send-verify-code`
			return http.post(path, data).then((res) => res.data.data)
		},
		saveTrainingSettings(data: TrainingSettings): Promise<TrainingSettings> {
			const path = `/api/admin/students/save-training-settings`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *分页查询
		 */
		getPageList(data: QueryMap<Student>): Promise<PageList<StudentDTO>> {
			const path = `/api/admin/students/get-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *新增
		 */
		create(data: Student): Promise<Student> {
			const path = `/api/admin/students/create`
			return http.post(path, data).then((res) => res.data.data)
		},
		getTrainingSettings(studentId: number): Promise<TrainingSettings> {
			const path = `/api/admin/students/get-training-settings`
			return http.get(path, { params: { studentId } }).then((res) => res.data.data)
		},
	},
	sentence: {
		/**
		 *添加句子
		 */
		save(data: Sentence): Promise<Sentence> {
			const path = `/api/admin/sentence/save`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *查询句子列表 - 分页查询
		 */
		getPageList(data: QueryMap<Sentence>): Promise<PageList<SentenceDTO>> {
			const path = `/api/admin/sentence/get-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *删除句子
		 */
		delete(id: number): Promise<boolean> {
			const path = `/api/admin/sentence/delete`
			return http.post(path, null, { params: { id } }).then((res) => res.data.data)
		},
		/**
		 *根据id查询单个句子信息
		 */
		get(id: number): Promise<Sentence> {
			const path = `/api/admin/sentence/get`
			return http.get(path, { params: { id } }).then((res) => res.data.data)
		},
	},
	roles: {
		/**
		 *修改
		 */
		update(data: Role): Promise<Role> {
			const path = `/api/admin/roles/update`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *分页查询
		 */
		getPageList(data: QueryMap<Role>): Promise<PageList<Role>> {
			const path = `/api/admin/roles/get-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *删除单条
		 */
		delete(data: Role): Promise<boolean> {
			const path = `/api/admin/roles/delete`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *新增
		 */
		create(data: Role): Promise<Role> {
			const path = `/api/admin/roles/create`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *对角色授权
		 */
		authorizeActions(roleId: number, data: number[]): Promise<number> {
			const path = `/api/admin/roles/authorize-actions`
			return http.post(path, data, { params: { roleId } }).then((res) => res.data.data)
		},
		/**
		 *根据主键获取单条
		 */
		get(roleId: number): Promise<Role> {
			const path = `/api/admin/roles/${roleId}`
			return http.get(path).then((res) => res.data.data)
		},
		/**
		 *获取角色权限
		 */
		getActionIds(roleId: number): Promise<number[]> {
			const path = `/api/admin/roles/get-action-ids`
			return http.get(path, { params: { roleId } }).then((res) => res.data.data)
		},
	},
	resources: {
		/**
		 *修改
		 */
		update(data: Resource): Promise<Resource> {
			const path = `/api/admin/resources/update`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *获取用户的permissions
		 */
		getUserPermissions(data: number): Promise<string[]> {
			const path = `/api/admin/resources/get-user-permissions`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *分页查询
		 */
		getPageList(data: QueryMap<Resource>): Promise<PageList<Resource>> {
			const path = `/api/admin/resources/get-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *删除单条
		 */
		delete(data: Resource): Promise<boolean> {
			const path = `/api/admin/resources/delete`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *新增
		 */
		create(data: Resource): Promise<Resource> {
			const path = `/api/admin/resources/create`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *根据主键获取单条
		 */
		get(id: number): Promise<Resource> {
			const path = `/api/admin/resources/${id}`
			return http.get(path).then((res) => res.data.data)
		},
		pb: {
			/**
			 *所有菜单
			 */
			getList(): Promise<Resource[]> {
				const path = `/api/admin/resources/pb/get-list`
				return http.get(path).then((res) => res.data.data)
			},
		},
	},
	organs: {
		/**
		 *修改
		 */
		update(data: Organ): Promise<Organ> {
			const path = `/api/admin/organs/update`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *分页查询
		 */
		getPageList(data: QueryMap<Organ>): Promise<PageList<Organ>> {
			const path = `/api/admin/organs/get-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *分页查询
		 */
		getList(): Promise<Organ[]> {
			const path = `/api/admin/organs/get-list`
			return http.post(path).then((res) => res.data.data)
		},
		/**
		 *删除单条
		 */
		delete(data: Organ): Promise<boolean> {
			const path = `/api/admin/organs/delete`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *新增
		 */
		create(data: Organ): Promise<Organ> {
			const path = `/api/admin/organs/create`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *根据主键获取单条
		 */
		get(id: number): Promise<Organ> {
			const path = `/api/admin/organs/${id}`
			return http.get(path).then((res) => res.data.data)
		},
	},
	institutions: {
		/**
		 *修改
		 */
		update(data: InstitutionDTO): Promise<Institution> {
			const path = `/api/admin/institutions/update`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *分页查询
		 */
		getPageList(data: QueryMap<Institution>): Promise<PageList<InstitutionDTO>> {
			const path = `/api/admin/institutions/get-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *删除单条
		 */
		delete(data: Institution): Promise<boolean> {
			const path = `/api/admin/institutions/delete`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *新增
		 */
		create(data: InstitutionDTO): Promise<InstitutionDTO> {
			const path = `/api/admin/institutions/create`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *根据主键获取单条
		 */
		get(id: number): Promise<Institution> {
			const path = `/api/admin/institutions/${id}`
			return http.get(path).then((res) => res.data.data)
		},
	},
	courseware: {
		/**
		 *保存课件
		 */
		saveCourseware(data: Courseware): Promise<Courseware> {
			const path = `/api/admin/courseware/saveCourseware`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *查询课件列表
		 */
		getList(data: QueryMap<Courseware>): Promise<Courseware[]> {
			const path = `/api/admin/courseware/get-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *删除课件
		 */
		delete(id: number): Promise<boolean> {
			const path = `/api/admin/courseware/delete`
			return http.post(path, null, { params: { id } }).then((res) => res.data.data)
		},
	},
	coursewareCategory: {
		save(data: CoursewareCategory): Promise<CoursewareCategory> {
			const path = `/api/admin/courseware-category/save`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *查询课件分类列表
		 */
		getPageList(
			data: QueryMap<CoursewareCategory>,
		): Promise<PageList<CoursewareCategory>> {
			const path = `/api/admin/courseware-category/get-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *查询课件分类列表
		 */
		getList(): Promise<CoursewareCategory[]> {
			const path = `/api/admin/courseware-category/get-list`
			return http.post(path).then((res) => res.data.data)
		},
		/**
		 *删除课件分类
		 */
		delete(id: number): Promise<boolean> {
			const path = `/api/admin/courseware-category/delete`
			return http.post(path, null, { params: { id } }).then((res) => res.data.data)
		},
	},
	beans: {
		subtract: {
			/**
			 *机构直接扣除学生豆子
			 */
			student(data: SubtractBeansVO): Promise<boolean> {
				const path = `/api/admin/beans/subtract/student`
				return http.post(path, data).then((res) => res.data.data)
			},
		},
		recharge: {
			/**
			 *机构给学生下发豆子
			 */
			student(params: { studentId: number; count: number }): Promise<BeansWater> {
				const path = `/api/admin/beans/recharge/student`
				return http.post(path, null, { params }).then((res) => res.data.data)
			},
			/**
			 *给机构下发豆子
			 */
			institution(params: { institutionId: number; count: number }): Promise<BeansWater> {
				const path = `/api/admin/beans/recharge/institution`
				return http.post(path, null, { params }).then((res) => res.data.data)
			},
		},
		/**
		 *获得学生豆子流水
		 */
		getStudentPageList(data: QueryMap<BeansWaterDTO>): Promise<PageList<BeansWaterDTO>> {
			const path = `/api/admin/beans/get-student-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *获得机构豆子流水
		 */
		getInstitutionPageList(
			data: QueryMap<BeansWaterDTO>,
		): Promise<PageList<BeansWaterDTO>> {
			const path = `/api/admin/beans/get-institution-page-list`
			return http.post(path, data).then((res) => res.data.data)
		},
	},
	audits: {
		/**
		 *修改
		 */
		update(data: Audit): Promise<Audit> {
			const path = `/api/admin/audits/update`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *分页查询
		 */
		getPageList(data: QueryMap<Audit>): Promise<PageList<Audit>> {
			const path = `/api/admin/audits/getPageList`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *删除单条
		 */
		delete(data: Audit): Promise<boolean> {
			const path = `/api/admin/audits/delete`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *新增
		 */
		create(data: Audit): Promise<Audit> {
			const path = `/api/admin/audits/create`
			return http.post(path, data).then((res) => res.data.data)
		},
		/**
		 *根据主键获取单条
		 */
		get(id: number): Promise<Audit> {
			const path = `/api/admin/audits/${id}`
			return http.get(path).then((res) => res.data.data)
		},
	},
	accounts: {
		refreshToken(data: AuthTokenDTO): Promise<AuthTokenDTO> {
			const path = `/api/admin/accounts/refreshToken`
			return http.post(path, data).then((res) => res.data.data)
		},
		pb: {
			register(data: RegisterVO): Promise<RegisterResult> {
				const path = `/api/admin/accounts/pb/register`
				return http.post(path, data).then((res) => res.data.data)
			},
			login(data: LoginVO): Promise<AuthTokenDTO> {
				const path = `/api/admin/accounts/pb/login`
				return http.post(path, data).then((res) => res.data.data)
			},
		},
	},
}
export default adminApi
