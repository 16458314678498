import React, {useCallback, useRef} from 'react'
import adminApi, {Institution, Role} from '@/api/admin'
import {ApiTable, Form, FormItem, overlay} from '@/components'
import ModalForm from '@/components/modal/modal-form'
import {getAuth} from '@/services/auth'
import {useHistory} from 'react-router-dom'

const institutionLables = {
  name: '机构名',
  beanNumbers: '豆子数',
  contractNumber: '联系电话',
  contractPerson: '联系人',
  contractAccount: '登录账号',
  contractPassword: '登录密码',
  update: '修改机构',
  create: '新增机构',
  totalRecharge: '累计充值',
}
const InstitutionEdit: ModalComponent<Institution> = (props) => {
  const isEdit = !!props.model.id
  return (
    <Form
      labels={institutionLables}
      initialValues={props.model}
      api={props.model?.id ? adminApi.institutions.update : adminApi.institutions.create}>
      <FormItem.Input name="name" required />
      <FormItem.Input name="contractAccount" required readOnly={isEdit} />
      <FormItem.Input name="contractPassword" required hidden={isEdit} />
      <FormItem.Input name="contractPerson" required readOnly={isEdit} />
      <FormItem.Input name="contractNumber" required readOnly={isEdit} />
    </Form>
  )
}
InstitutionEdit.defaultModalProps = (props) => {
  return {title: props.model?.id ? institutionLables.update : institutionLables.create}
}
const InstitutionRecharge: ModalComponent<Institution> = (props) => {
  return (
    <Form
      initialValues={{institutionId: props.model.id, name: props.model.name}}
      api={adminApi.beans.recharge.institution}>
      <FormItem.Input label="充值机构" name="name" readOnly />
      <FormItem.InputNumber label="充值数目" name="count" required />
    </Form>
  )
}
InstitutionRecharge.defaultModalProps = (props) => {
  return {title: '机构充值'}
}
const InstitutionPage: PageComponent = () => {
  const history = useHistory()
  const authRef = useRef(getAuth())

  const deleteRole = useCallback(async (data: Institution) => {
    if (await overlay.deleteConfirm()) {
      return adminApi.institutions.delete(data)
    }
  }, [])

  return (
    <ApiTable
      labels={institutionLables}
      api={adminApi.institutions.getPageList}
      tableTitle="机构列表"
      toolBar={[
        {
          title: institutionLables.create,
          component: InstitutionEdit,
          buttonType: 'create',
        },
      ]}
      columns={[
        {valueType: 'index'},
        {dataIndex: 'name', operator: 'like'},
        {dataIndex: 'contractAccount'},
        {dataIndex: 'contractPerson'},
        {dataIndex: 'contractNumber'},
        {dataIndex: 'beanNumbers'},
        {dataIndex: 'totalRecharge'},
        {
          actions: [
            {
              component: InstitutionEdit,
              title: '编辑',
            },

            {
              onClick: deleteRole,
              title: '删除',
            },
            {
              component: InstitutionRecharge,
              title: '机构充值',
            },
          ],
        },
      ]}
    />
  )
}
InstitutionPage.options = {name: '机构管理', path: '/institution/manage'}
export default InstitutionPage
