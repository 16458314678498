import React, {useState} from 'react';
import styles from './register.module.less';
import adminApi, {RegisterResult, RegisterVO} from '@/api/admin';
import {Form, FormItem} from '@/components';
import {Link} from '@/utils/routerUtils';
import UserLayout from './components/UserLayout';
import {useHistory} from 'react-router-dom';

type PasswordStatus = 'ok' | 'pass' | 'poor'
const Login: PageComponent = () => {
  let model: RegisterVO = {
    account: undefined,
    password: undefined,
    phone: undefined
  };
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [popover, setPopover] = useState(false);
  const history = useHistory();
  const onSuccess = (data: RegisterResult) => {
    alert('注册成功');
    history.push('/user/login');
  };
  const getPasswordStatus = (): PasswordStatus => {
    const value = form.getFieldValue('password');
    if(value && value.length > 9) {
      return 'ok';
    }
    if(value && value.length > 5) {
      return 'pass';
    }
    return 'poor';
  };
  // const checkConfirm = (_: any, value: string) => {
  //   const promise = Promise
  //   if (value && value !== form.getFieldValue('password')) {
  //     return promise.reject(formatMessage({id: 'BLOCK_NAME.password.twice'}))
  //   }
  //   return promise.resolve()
  // }
  // const checkPassword = (_: any, value: string) => {
  //   const promise = Promise;
  //   // 没有值的情况
  //   if (!value) {
  //     setVisible(!!value);
  //     return promise.reject(formatMessage({ id: 'BLOCK_NAME.password.required' }));
  //   }
  //   // 有值的情况
  //   if (!visible) {
  //     setVisible(!!value);
  //   }
  //   setPopover(!popover);
  //   if (value.length < 6) {
  //     return promise.reject('');
  //   }
  //   if (value && confirmDirty) {
  //     form.validateFields(['confirm']);
  //   }
  //   return promise.resolve();
  // };
  return (
    <UserLayout containerClass={styles.main}>
      <Form
        onSuccess={onSuccess}
        initialValues={model}
        noLabel
        api={adminApi.accounts.pb.register}>
        <h3>注册</h3>
        <FormItem.Input
          label="帐号"
          name="account"
          size="large"
          required
          placeholder="请输入帐号"
          requiredPrefix="请输入帐号"
        />
        <FormItem.Input
          label="密码"
          name="password"
          size="large"
          type="password"
          required
          placeholder="至少6位密码，区分大小写"
          requiredPrefix="至少6位密码，区分大小写"
        />
        <FormItem.Input
          label="确认密码"
          name="password"
          size="large"
          type="password"
          required
          placeholder="请输入确认密码"
          requiredPrefix="请输入确认密码"
        />
        <FormItem.Input
          label="手机号"
          name="phone"
          size="large"
          required
          placeholder="请输入手机号"
          requiredPrefix="请输入手机号"
        />
        <FormItem.SubmitButton size="large" className={styles.submit} title="注册"/>

        <div className={styles.other}>
          <Link className={styles.register} to="/user/login">
            使用已有账户登录
          </Link>
        </div>
        {/* <FormItem.SubmitButton size="large" className={styles.submit} title="登录" /> */}
      </Form>
    </UserLayout>
  );
};
Login.options = {name: '注册', layout: 'public'};
export default Login;
