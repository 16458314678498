import React, {useEffect} from 'react'
import {RouteComponentProps} from 'react-router-dom'
import {getMenuByPath} from '@/services/menu'
export default function withTitle<T>(Component: PageComponent<T>) {
  const WithTitleComponent: PageComponent<RouteComponentProps> = (props) => {
    useEffect(() => {
      const pathname = props.location.pathname
      let currMenu = getMenuByPath(pathname)
      document.title = currMenu
        ? currMenu.name
        : (Component as PageComponent).options?.name
    }, [props.location.pathname])
    return <Component {...props} />
  }
  WithTitleComponent.options = Component.options
  return WithTitleComponent
}
