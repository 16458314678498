import {
  SearchOutlined,
  PlusOutlined,
  SettingOutlined,
  LoadingOutlined,
  DownloadOutlined,
  EditOutlined,
  UploadOutlined,
  DeleteOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons'
export const icons = {
  plus: PlusOutlined,
  search: SearchOutlined,
  setting: SettingOutlined,
  loading: LoadingOutlined,
  create: PlusOutlined,
  download: DownloadOutlined,
  upload: UploadOutlined,
  edit: EditOutlined,
  delete: DeleteOutlined,
  play: PlayCircleOutlined,
}
export {
  SearchOutlined,
  PlusOutlined,
  SettingOutlined,
  LoadingOutlined,
  DownloadOutlined,
  EditOutlined,
  UploadOutlined,
  DeleteOutlined,
  PlayCircleOutlined,
}
export type IconKey = keyof typeof icons
export default icons
