import React from 'react'
import callApi from '@/utils/callApi'
import ActionButton, {ActionButtonProps} from './ActionButton'
import {useTable} from '../api-table/ApiTable'
import {exportExcel} from '@/utils/excelUtils'
import icons from '../icons'
export interface ExportProps {
  fileName?: string
}
export default function ExportButton<D>(props: ActionButtonProps<D> & ExportProps) {
  let {fileName, icon, ...restProps} = props
  let table = useTable()
  if (!icon) {
    icon = 'download'
  }
  return (
    <ActionButton
      icon={icon}
      onSuccess={() => {}}
      {...restProps}
      onClick={async () => {
        let data = await table.props.api({pageSize: -1})
        await exportExcel({
          sheets: [
            {sheetName: table.props.tableTitle, columns: table.columns, data: data.items},
          ],
        })
        // table.search
        // let filePath = await callApi(restProps)
        // window.location.href = `/api/public/download?filePath=${filePath}&fileName=${fileName}.xlsx`
      }}
    />
  )
}
