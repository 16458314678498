import adminApi from '@/api/admin'
import {message} from 'antd'

export async function translate(en: string) {
  let res = await adminApi.word.translate({en})
  if (res.errorCode !== '0') {
    message.error('识别出错')
    return
  }
  let cn = ''
  let ipa = ''
  let audioUrl = res.audioUrl
  if (res.basic && res.basic.explains) {
    ipa = `[${res.basic['uk-phonetic']}]`
    let cns = []
    for (let idx = 0; idx < Math.min(3, res.basic.explains.length); idx++) {
      cns.push(res.basic.explains[idx].split(';')[0].split('；')[0])
    }
    cn = cns.join('；')
  } else if (res.translation) {
    cn = res.translation[0] || ''
  } else if (res.web) {
    const web = res.web[0]
    cn = web?.value?.[0] || ''
  }
  return {cn, ipa, audioUrl}
}
