import React, {useState, useCallback} from 'react'

export interface AppConfig {
  routes: Record<string, PageComponent>
  menus: Menu[]
  logo?: any
  siteName?: string
  loginUrl?: string
  toggle?: () => void
  collapsed?: boolean
}

const AppConfig = React.createContext<AppConfig>({} as any)
export const AppConfigProvider: React.FC<AppConfig> = (props) => {
  const [collapsed, setCollapse] = useState(false)
  let {children, ...rest} = props
  const toggle = useCallback(() => {
    setCollapse((collapsed) => !collapsed)
  }, [])
  return (
    <AppConfig.Provider value={{collapsed, toggle, ...rest}}>
      {children}
    </AppConfig.Provider>
  )
}
export default AppConfig
export const useAppConfig = () => React.useContext(AppConfig)
