import adminApi, {
  Courseware,
  CoursewareCategory,
  CoursewareType,
  getCoursewareTypeText,
} from '@/api/admin'
import {DataNode} from 'antd/lib/tree'
import React, {useCallback, useState} from 'react'
import {TreeSelect} from 'antd'
import ApiSideTree from '@/components/tree/ApiSideTree'
import {Form, FormItem, Loading, overlay} from '@/components'

const labels = {
  createCourseware: '新增课件',
  editCourseware: '编辑课件',
  deleteCourseware: '删除课件',
  name: '课件名称',
  price: '课件价格',
}
interface SelectedValue {
  coursewareId?: number
}
interface Props {
  type: CoursewareType
  onSelect: (data: SelectedValue) => any
}
const CoursewareEdit: ModalComponent<Courseware> = (props) => {
  return (
    <Form
      labels={labels}
      initialValues={props.model}
      api={adminApi.courseware.saveCourseware}>
      <FormItem.Input name="name" required />
      <FormItem.Input type="number" name="price" required />
    </Form>
  )
}
const CoursewareSideTree: React.FC<Props> = (props) => {
  let {type, onSelect} = props
  const [course, setCourse] = useState({type} as Courseware)
  let [treeData, setTreeData] = useState([] as DataNode[])
  const onSelectGrade = useCallback((key: string) => {
    setCourse((prev) => ({...prev, categoryId: Number(key)}))
  }, [])
  const onCreateCourse = useCallback(() => {
    return overlay.showForm(CoursewareEdit, course)
  }, [course])
  const deleteCourseware = useCallback(async (data: Courseware) => {
    if (await overlay.deleteConfirm()) {
      await adminApi.courseware.delete(data.id)
    }
  }, [])
  const getCate = useCallback(async () => {
    let cates = await adminApi.coursewareCategory.getList()

    let treeMap: Record<string, DataNode> = {}
    let treeData: DataNode[] = []
    for (let cate of cates) {
      let node = {
        key: cate.id,
        title: cate.name,
        value: cate.id,
        selectable: !!cate.parentId,
      }
      if (treeMap[cate.parentId]) {
        let parent = treeMap[cate.parentId]
        parent.children = parent.children || []
        node.title = parent.title + '-' + node.title
        parent.children.push(node)
      } else {
        treeData.push(node)
      }

      treeMap[node.key] = node
    }

    setTreeData(treeData)
  }, [])
  const searchBar = (
    <TreeSelect
      virtual={false}
      showSearch
      onSelect={onSelectGrade}
      style={{width: '100%'}}
      dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
      placeholder="选择课件分类"
      treeData={treeData}
      treeDefaultExpandAll></TreeSelect>
  )
  return (
    <Loading loadData={getCate}>
      <ApiSideTree
        sideWidth={270}
        searchBar={searchBar}
        api={adminApi.courseware.getList}
        data={course}
        onApiBefore={(data) => !!data.categoryId}
        title={getCoursewareTypeText(type)}
        toolBar={[
          {
            title: labels.createCourseware,
            onClick: onCreateCourse,
            hidden: !course.categoryId,
          },
        ]}
        onSelect={(data) => onSelect({coursewareId: data.id})}
        nodeActions={[
          {icon: 'edit', title: labels.editCourseware, component: CoursewareEdit},
          {icon: 'delete', title: labels.deleteCourseware, onClick: deleteCourseware},
        ]}>
        {props.children}
      </ApiSideTree>
    </Loading>
  )
}
export default CoursewareSideTree
