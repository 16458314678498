import {hasPermission} from './auth'
const menuMap = new Map<string, Menu>()
export const getMenuByPath = (path: string) => {
  return menuMap.get(path)
}
export const getOpenKeys = (selectKey: string) => {
  if (!selectKey) return []

  let openKeys = []
  // let lastIndex = selectKey.lastIndexOf('.')
  let index = selectKey.indexOf('.')
  while (index !== -1) {
    openKeys.push(selectKey.substr(0, index))
    index = selectKey.indexOf('.', index + 1)
  }

  return openKeys
}
export function getUserMenu(menus: Menu[], parentMenu?: Menu) {
  let userMenu: Menu[] = []
  if (!menus || menus.length === 0) return userMenu
  for (let menu of menus) {
    menuMap.set(menu.path, menu)
    menu.key = `${parentMenu ? parentMenu.key + '.' : ''}${menu.name}`
    if (menu.children) {
      menu.children = getUserMenu(menu.children, menu)
      if (menu.children.length) {
        userMenu.push(menu)
      }
    } else if (hasPermission(menu.path)) {
      userMenu.push(menu)
    }
  }
  return userMenu
}
