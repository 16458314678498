import React, {useMemo, useState, useEffect} from 'react'
import {Layout, Menu as AntdMenu} from 'antd'
import {Link, useLocation} from 'react-router-dom'
import './index.css'
import {useAppConfig} from '@/layout/appConfig'
import {getUserMenu, getMenuByPath, getOpenKeys} from '@/services/menu'
const {Sider} = Layout

function getMenuItems(menus: Menu[], parentPath?: string) {
  return menus.map((menu) => {
    if (!menu.children) {
      return (
        <AntdMenu.Item key={menu.key}>
          <Link to={menu.path}>
            <span>{menu.name}</span>
          </Link>
        </AntdMenu.Item>
      )
    } else {
      return (
        <AntdMenu.SubMenu key={menu.key} title={<span>{menu.name}</span>}>
          {getMenuItems(menu.children, menu.path)}
        </AntdMenu.SubMenu>
      )
    }
  })
}

const SiderMenu: React.FC<{menus: Menu[]}> = (props) => {
  const {pathname} = useLocation()

  const appContext = useAppConfig()

  const menuItems = useMemo(() => {
    let menus = getUserMenu(appContext.menus)

    return getMenuItems(menus)
  }, [appContext.menus])
  let [selectedKeys, setSelectedKeys] = useState([])
  let [openKeys, setOpenKeys] = useState([])
  useEffect(() => {
    let menu = getMenuByPath(pathname)
    if (!menu) {
      return
    }
    let selectedKey = menu.key
    setSelectedKeys([selectedKey])
    setOpenKeys(getOpenKeys(selectedKey))
  }, [pathname])

  return (
    <Sider
      trigger={null}
      collapsible
      theme="light"
      className="sider-fixed"
      collapsed={appContext.collapsed}>
      <AntdMenu
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={(value: any) => setOpenKeys(value)}>
        {menuItems}
      </AntdMenu>
    </Sider>
  )
}
export default SiderMenu
