import React, {useCallback, useState} from 'react'
import adminApi, {Word, CoursewareType} from '@/api/admin'
import {ApiTable, Form, FormItem, overlay} from '@/components'
import CoursewareSideTree from './components/CoursewareSideTree'
import {Button, message, Tooltip, Upload} from 'antd'
import AudioIcon from '@/components/AudioIcon'
import LoadingButton from '@/components/loading/LoadingButton'
import LoadingAnchor from '@/components/loading/LoadingAnchor'
import {translate} from './components/translateUtils'
import UploadButton from '@/components/UploadButton'

const labels = {
  en: '单词英译',
  cn: '单词中译',
  ipa: '音标',
  audioUrl: '音频文件',
}
const WordEdit: ModalComponent<Word> = (props) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState(props.model)
  const onClick = useCallback(async () => {
    let en: string = form.getFieldValue('en')
    if (!en) {
      message.warning('请输入' + labels.en)
      return
    }
    let res = await translate(en)
    if (res) {
      form.setFieldsValue(res)
      setFormData((prev) => ({...prev, ...form.getFieldsValue()}))
    }
  }, [form])
  return (
    <Form form={form} initialValues={formData} labels={labels} api={adminApi.word.save}>
      <Form.Item label="单词英译">
        <FormItem.Input name="en" required noStyle style={{width: 240}} />
        <Tooltip title="自动获取">
          <LoadingAnchor onClick={onClick}>自动识别</LoadingAnchor>
        </Tooltip>
      </Form.Item>
      <FormItem.TextArea rows={2} name="cn" required />
      <FormItem.Input name="ipa" required />
      <FormItem.Item name="audioUrl">
        <AudioIcon src={formData.audioUrl} />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <UploadButton
          title="上传音频"
          onSuccess={(audioUrl) => {
            form.setFieldsValue({audioUrl})
            setFormData((prev) => ({...prev, ...form.getFieldsValue()}))
          }}
        />
      </FormItem.Item>
    </Form>
  )
}
WordEdit.defaultModalProps = (props) => {
  return {title: props.model?.id ? '编辑单词' : '新增单词'}
}
const WordPage: PageComponent = () => {
  const [word, setWord] = useState<Word>({})

  const onDelete = useCallback(async (data: Word) => {
    if (await overlay.deleteConfirm()) {
      await adminApi.word.delete(data.id)
    }
  }, [])

  return (
    <CoursewareSideTree type={CoursewareType.WORD} onSelect={setWord}>
      <ApiTable
        api={adminApi.word.getPageList}
        data={word}
        toolBar={[
          {
            component: WordEdit,
            buttonType: 'create',
            title: '新建单词',
            hidden: !word.coursewareId,
          },
        ]}
        onApiBefore={(data) => {
          return !!data.coursewareId
        }}
        pagination={false}
        labels={labels}
        tableTitle="单词列表"
        columns={[
          {valueType: 'index'},
          {dataIndex: 'en'},
          {dataIndex: 'cn'},
          {dataIndex: 'ipa'},
          {dataIndex: 'audioUrl', valueType: 'audio'},
          {
            title: '操作',
            actions: [
              {
                component: WordEdit,
                title: '编辑',
              },
              {
                onClick: onDelete,
                title: '删除',
              },
            ],
          },
        ]}
      />
    </CoursewareSideTree>
  )
}
WordPage.options = {name: '数据字典', path: '/auth/system-option'}
export default WordPage
