import React, {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {isInBrowser} from './common'
type SetStateFunc<T> = Dispatch<SetStateAction<T>>
interface WithShareStateProps<T> {
  shareState: [T, SetStateFunc<T>]
}

export function withShareState<T extends {}>(defaultValue: T) {
  let shareValue = defaultValue

  const listeners = new Set<SetStateFunc<T>>()
  const addListener = (listener: SetStateFunc<T>) => {
    listeners.add(listener)
  }
  const removeListener = (listener: SetStateFunc<T>) => {
    listeners.delete(listener)
  }
  function setShareState(value: SetStateAction<T>) {
    if (typeof value === 'function') {
      shareValue = (value as any)(shareValue) as T
    } else {
      shareValue = value
    }

    listeners.forEach((setValue) => {
      setValue && setValue(shareValue)
    })
  }

  function ShareStateComponent<P>(
    Component: React.ComponentType<P & WithShareStateProps<T>>,
  ) {
    const WithStoreComponent: React.FC<P> = (props) => {
      const [_, setValue] = useState(shareValue)
      useEffect(() => {
        addListener(setValue)
        return () => removeListener(setValue)
      }, [])
      return <Component {...props} shareState={[shareValue, setShareState]} />
    }
    return WithStoreComponent
  }
  function useShareState(): [T, SetStateFunc<T>] {
    let [_, setState] = useState(shareValue)
    useEffect(() => {
      addListener(setState)
      return () => removeListener(setState)
    }, [])
    return [shareValue, setShareState]
  }
  ShareStateComponent.useShareState = useShareState
  ShareStateComponent.setShareState = setShareState
  ShareStateComponent.addListener = addListener
  ShareStateComponent.removeListener = removeListener
  ShareStateComponent.getShareState = () => shareValue
  return ShareStateComponent
}

export function withStore<T extends {}>(persistKey: string, defaultValue: T) {
  let persistValue: string | null = null
  if (isInBrowser()) {
    persistValue = localStorage?.getItem(persistKey)
  }
  if (persistValue) {
    defaultValue =
      typeof defaultValue === 'object' ? JSON.parse(persistValue) : persistValue
  }
  // console.debug('persistValue', defaultValue)
  let shareState = withShareState(defaultValue)
  shareState.addListener((data) => {
    console.warn('write store ' + persistKey)
    if (typeof data === 'object') {
      localStorage.setItem(persistKey, JSON.stringify(data))
    } else {
      localStorage.setItem(persistKey, data.toString())
    }
  })
  return shareState
}
