//自动生成代码
import ExerciseCategory from '../pages/exercise/category'
import ExerciseSentence from '../pages/exercise/sentence'
import ExerciseWord from '../pages/exercise/word'
import InstitutionInstitutionWaterFlow from '../pages/institution/institution-water-flow'
import InstitutionManage from '../pages/institution/manage'
import InstitutionStudentWaterFlow from '../pages/institution/student-water-flow'
import InstitutionStudent from '../pages/institution/student'
import SettingsMenu from '../pages/settings/menu'
import SettingsRole from '../pages/settings/role'
import SettingsSystemOption from '../pages/settings/system-option'
import SettingsSystemParam from '../pages/settings/system-param'
import SettingsUser from '../pages/settings/user'
import UserLogin from '../pages/user/Login'
import UserRegister from '../pages/user/register'
const routes = {
  '/exercise/category': ExerciseCategory,
  '/exercise/sentence': ExerciseSentence,
  '/exercise/word': ExerciseWord,
  '/institution/institution-water-flow': InstitutionInstitutionWaterFlow,
  '/institution/manage': InstitutionManage,
  '/institution/student-water-flow': InstitutionStudentWaterFlow,
  '/institution/student': InstitutionStudent,
  '/settings/menu': SettingsMenu,
  '/settings/role': SettingsRole,
  '/settings/system-option': SettingsSystemOption,
  '/settings/system-param': SettingsSystemParam,
  '/settings/user': SettingsUser,
  '/user/login': UserLogin,
  '/user/register': UserRegister,
}
export type RouteKey = keyof typeof routes
export default routes
