//自动生成代码
export default [
  {
    name: '机构管理',
    type: 1,
    path: '/institution',
    children: [
      {name: '机构信息', type: 2, path: '/institution/manage'},
      {name: '学生管理', type: 2, path: '/institution/student'},
      {name: '学生消费记录', type: 2, path: '/institution/student-water-flow'},
      {name: '机构流水记录', type: 2, path: '/institution/institution-water-flow'},
    ],
  },
  {
    name: '习题管理',
    type: 1,
    path: '/exercise',
    children: [
      {name: '课件分类管理', type: 2, path: '/exercise/category'},
      {name: '单词管理', type: 2, path: '/exercise/word'},
      {name: '句子管理', type: 2, path: '/exercise/sentence'},
    ],
  },
  {
    name: '系统设置',
    type: 1,
    path: '/settings',
    children: [
      {name: '菜单管理', type: 2, path: '/settings/menu'},
      {name: '角色管理', type: 2, path: '/settings/role'},
      {name: '数据字典', type: 2, path: '/settings/system-option'},
      {name: '系统参数', type: 2, path: '/settings/system-param'},
    ],
  },
]
