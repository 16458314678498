import React from 'react'
import ReactDOM from 'react-dom'
import routes from './pages/routes'
import menus from './pages/menus'
import {AppContainer} from '@/layout'
import './assets/global.less'
import config from './config/config'
import trainApi, {GradeEnum, RingType} from './api/train'
function App() {
  return (
    <AppContainer
      routes={routes}
      menus={menus}
      logo="/favicon.png"
      siteName={config.siteName}
      loginUrl="/user/login"
    />
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
