import {AuthTokenDTO} from '@/api/admin'
import {withStore} from '@/utils/shareState'

let permissionSet = new Set<string>()
export interface AuthToken extends AuthTokenDTO {
  isLogin?: boolean
}
const defaultLogin: AuthToken = {isLogin: false}
const withAuth = withStore('auth', defaultLogin)
const setPermissions = (permissions: string[]) => {
  permissionSet.clear()
  permissions?.forEach((per) => {
    permissionSet.add(per)
    permissionSet.add(per.split(':')[0])
  })
}
setPermissions(withAuth.getShareState().permissions)

export const logout = () => {
  withAuth.setShareState(defaultLogin)

  // eslint-disable-next-line
  // window.location.href = window.location.href
}
export const login = (token: AuthToken) => {
  setPermissions(token.permissions)
  withAuth.setShareState({...token, isLogin: true})
}
export const useAuth = withAuth.useShareState
export const getAuth = withAuth.getShareState
export const hasPermission = (actionCode: string) => {
  if (getAuth().isSuperAdmin) return true
  if (!actionCode) return true
  return (
    permissionSet.has(actionCode) ||
    permissionSet.has(window.location.pathname + ':' + actionCode)
  )
}
