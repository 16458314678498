import React, {useState, useEffect, useRef} from 'react'
import {Spin} from 'antd'
interface Props {
  loadData: () => Promise<any>
}
const Loading: React.FC<Props> = (props) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState('')
  const fetchData = useRef(() => {
    setLoaded(false)
    setError('')
    props
      .loadData()
      .catch((ex) => {
        setError(ex)
      })
      .finally(() => {
        setLoaded(true)
      })
  })
  useEffect(() => fetchData.current(), [])
  if (!loaded) return <Spin />
  else {
    return <>{props.children}</>
  }
}
export default Loading
