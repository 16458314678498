import React, {useEffect} from 'react'
import adminApi, {BeansTypeEnum, BeansTypeEnumOptions, GradeEnum} from '@/api/admin'
import {ApiTable} from '@/components'
import {useAuth} from '@/services/auth'

const labels = {
  studentName: '学生姓名',
  operatorType: '变更类型',
  beansCount: '变更数',
  businessContent: '消费内容',
  createdDate: '消费时间',
}

const WaterFlowPage: PageComponent = (props) => {
  const [auth] = useAuth()
  if (auth.isSuperAdmin) {
    return (
      <ApiTable
        data={{
          operatorType: {
            $notIn: [
              BeansTypeEnum.STUDENT_RECHARGE_INS,
              BeansTypeEnum.INSTITUTION_RECHARGE,
            ],
          },
        }}
        api={adminApi.beans.getStudentPageList}
        labels={labels}
        tableTitle="学生消费记录列表"
        columns={[
          {valueType: 'index'},
          {dataIndex: 'studentName', operator: 'like'},
          {dataIndex: 'operatorType', options: BeansTypeEnumOptions},
          {
            dataIndex: 'beansCount',
          },
          {dataIndex: 'createdDate', valueType: 'date'},
        ]}
      />
    ) 
  } else {
    return (
      <ApiTable
        data={{
          institutionId: auth.institutionId,
          operatorType: {
            $notIn: [
              BeansTypeEnum.STUDENT_RECHARGE_INS,
              BeansTypeEnum.INSTITUTION_RECHARGE,
            ],
          },
        }}
        api={adminApi.beans.getStudentPageList}
        labels={labels}
        tableTitle="学生消费记录列表"
        columns={[
          {valueType: 'index'},
          {dataIndex: 'studentName', operator: 'like'},
          {dataIndex: 'operatorType', options: BeansTypeEnumOptions},
          {
            dataIndex: 'beansCount',
          },
          {dataIndex: 'createdDate', valueType: 'date'},
        ]}
      />
    )
  }
}

WaterFlowPage.options = {name: '豆子流水', path: '/institution/student-water-flow'}
export default WaterFlowPage
