import React, {useMemo} from 'react'
import {Layout} from 'antd'
import {useAppConfig} from './appConfig'
import {Route, useLocation, Redirect} from 'react-router-dom'
import GlobalHeader from './GlobalHeader'
import SiderMenu from './SiderMenu'
import {hasPermission, useAuth} from '@/services/auth'
const {Content} = Layout
const ProtectedRoute: React.FC<{routes: JSX.Element[]; menus: Menu[]}> = (props) => {
  const appConfig = useAppConfig()
  const [auth] = useAuth()
  const location = useLocation()
  const protectedChildren = useMemo(() => {
    if (hasPermission(location.pathname)) return props.routes
  }, [props.routes, location.pathname])
  // const userMenu = useMemo(() => permission.getUserMenu(props.menus), [
  //   permission,
  //   props.menus,
  // ])

  if (!auth.isLogin && appConfig.loginUrl !== location.pathname) {
    return <Redirect to={`${appConfig.loginUrl}?redirect=${location.pathname}`} />
  }

  const layout = () => (
    <Layout hasSider style={{minHeight: '100%'}}>
      <div className="globalLeft"></div>
      <SiderMenu menus={props.menus} />
      <Layout style={{position: 'relative'}}>
        <GlobalHeader />
        <Content className="globalcontent">{protectedChildren}</Content>
      </Layout>
    </Layout>
  )
  return <Route path="/" render={layout} />
}
export default ProtectedRoute
