export const genderTypes = [
  {value: 0, label: '男'},
  {value: 1, label: '女'},
]
export const userLabels = {
  account: '用户账号',
  password: '用户密码',
  name: '用户姓名',
  phone: '手机号码',
  email: '邮箱',
  status: '用户状态',
  update: '编辑',
  disable: '禁用',
  create: '新增用户',
  headImg: '用户头像',
  gender: '性别',
  resetPassword: '重置密码',
  newPassword: '新密码',
}
export const userStatus = [
  {value: 0, label: '正常'},
  {value: 1, label: '禁用', style: {color: '#ff4d4f'}},
]
