import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react'
import {TablePaginationConfig} from 'antd/lib/table'
import {Table, Card} from 'antd'
import {ExpandableConfig} from 'antd/lib/table/interface'
import {toSearchData, translateColumns} from './tableUtils'
import {TableInstance, TableProps} from './interface'
import SearchForm from './SearchForm'
import ToolBar from './ToolBar'
import './index.css'
import {normalizeOptions, ValueOption, OptionType, isApiOptions} from '../select'
import {toTreeData, TreeEntity} from '../tree/treeUtils'
const defaultPaginationConfig: TablePaginationConfig = {pageSize: 10, current: 1}
const defaultExpandableConfig: ExpandableConfig<any> = {
  defaultExpandAllRows: true,
}

export const TableContext = React.createContext({} as TableInstance)
export const useTable = () => React.useContext(TableContext)
export default function ApiTable<T extends object, P extends PageRequest>(
  props: TableProps<T, P>,
) {
  let [dataSource, setDataSource] = useState([] as T[])
  let queryDataRef = useRef({
    ...props.data,
    pageIndex: 1,
    pageSize: props.pagination === false ? -1 : props.pagination?.pageSize || 10,
  })

  let pageRef = useRef<TablePaginationConfig>({
    pageSize: queryDataRef.current.pageSize,
    current: queryDataRef.current.pageIndex,
    showSizeChanger: true,
    hideOnSinglePage: true,
    ...props.pagination,
  })

  let [loading, setLoading] = useState(false)
  let fetchData = useRef(async (param?: Partial<P>) => {
    if (loading) return
    queryDataRef.current = {...queryDataRef.current, ...param}

    if (props.onApiBefore) {
      let canInvoke = await props.onApiBefore(queryDataRef.current)
      if (!canInvoke) {
        return
      }
    }
    setLoading(true)
    try {
      let dataSource: T[] = []
      let total = 0
      if (!props.api) return
      let searchData = toSearchData(queryDataRef.current, props.columns)
      let apiResult = await props.api(searchData)

      dataSource = apiResult.items
      total = apiResult.total

      dataSource.forEach(
        (item: any, index: number) =>
          (item._index =
            (queryDataRef.current.pageIndex - 1) * queryDataRef.current.pageSize +
            index +
            1),
      )

      if (props.useTreeTable) {
        pageRef.current.pageSize = dataSource.length
        //@ts-ignore
        dataSource = toTreeData(dataSource)
      }
      pageRef.current.total = total
      pageRef.current.current = queryDataRef.current.pageIndex
      props.OnApiSuccess?.(apiResult)
      setDataSource(dataSource)
    } catch (ex) {
      // console.warn(ex)
    } finally {
      setLoading(false)
    }
  })
  const onTableChange = useCallback(
    (page: TablePaginationConfig, filters: any, sorter: any) => {
      let pageParam: PageRequest = {pageSize: page.pageSize, pageIndex: page.current}
      if (sorter) {
        if (sorter.order) {
          pageParam.orderBy = `${sorter.field} ${
            sorter.order === 'descend' ? 'desc' : 'asc'
          }`
        } else {
          pageParam.orderBy = undefined
        }
      }
      fetchData.current(pageParam as any)
    },
    [],
  )
  const search = useRef((data?: any) => {
    queryDataRef.current = {
      ...props.data,
      pageIndex: 1,
      pageSize: props.pagination === false ? -1 : props.pagination?.pageSize || 10,
    }

    return fetchData.current(data)
  })
  useEffect(() => {
    let data = {...props.data}

    for (let key of Object.keys(data)) {
      if (key in queryDataRef.current) {
        //@ts-ignore
        if (data[key] !== queryDataRef.current[key]) {
          search.current(data)
          return
        }
      } else {
        search.current(data)
        return
      }
    }
  }, [props.data])
  const apiOptions = useRef<Record<string, Promise<OptionType[]>>>({})
  const [options, setOptions] = useState(() => {
    let options: Record<string, ValueOption[]> = {}
    for (let col of props.columns) {
      if (col.dataIndex && col.options) {
        let api = col.options
        if (isApiOptions(api)) {
          apiOptions.current[col.dataIndex.toString()] = api(col.optionsParam)
        } else {
          options[col.dataIndex.toString()] = normalizeOptions(api)
        }
      }
    }
    return options
  })
  useEffect(() => {
    Promise.all(Object.values(apiOptions.current)).then((res) => {
      setOptions((options) => {
        let keys = Object.keys(apiOptions.current)
        for (let idx = 0; idx < keys.length; idx++) {
          options[keys[idx]] = normalizeOptions(res[idx])
        }
        return options
      })
    })
  }, [])
  const columns = useMemo(() => {
    let columns = translateColumns(props.columns)
    columns.forEach((col) => {
      col.title = col.title ?? props.labels?.[col.dataIndex?.toString()]
      col.actions?.map((a) => (a.title = a.title ?? props.labels?.[a.code!]))
    })
    return columns
  }, [props.columns, props.labels])

  const table: TableInstance<T> = {
    props,
    dataSource,
    setDataSource,
    columns,
    options,
    data: queryDataRef.current,
    search: search.current,
    reset: search.current,
    reload: fetchData.current,
  }

  if (props.table) {
    Object.assign(props.table, table)
  }
  useEffect(() => {
    search.current()
  }, [])

  props.toolBar?.forEach((op) => (op.title = op.title ?? props.labels?.[op.code!]))
  return (
    <TableContext.Provider value={table}>
      <div className="api-table">
        <SearchForm />
        <Card
          bodyStyle={{height: '100%', padding: 0}}
          title={props.tableTitle}
          extra={<ToolBar items={props.toolBar} />}>
          <Table<T>
            scroll={{x: 'max-content'}}
            key={dataSource.length}
            rowKey={props.rowKey ?? 'id'}
            size="small"
            expandable={{defaultExpandAllRows: true}}
            {...props}
            loading={loading}
            onChange={onTableChange}
            dataSource={dataSource}
            pagination={pageRef.current.pageSize > 0 && pageRef.current}
            columns={columns}
          />
        </Card>
      </div>
    </TableContext.Provider>
  )
}

// const ForwardApiTable = React.forwardRef(ApiTable)
// ForwardPageTable.IndexColumn = IndexColumn
// todo: 没找到使用functioncompoent泛型并支持ref的方法,故使用class包裹一层
