import React, {useCallback} from 'react'
import {Tabs} from 'antd'
import {ApiTable, Form, FormItem, overlay} from '@/components'
import adminApi, {CoursewareCategory, GradeEnumOptions} from '@/api/admin'

const tabLabels: Record<keyof CoursewareCategory | string, string> = {
  name: '分类名称',
  createdByName: '创建人',
  createdDate: '创建时间',
  updatedByName: '最后操作人',
  updatedDate: '最后操作时间',
  grade: '关联年级',
}
const CoursewareCategoryForm: ModalComponent<CoursewareCategory> = (props) => {
  let {model} = props
  console.warn('model', model)

  return (
    <Form
      api={adminApi.coursewareCategory.save}
      labels={tabLabels}
      initialValues={props.model}>
      <FormItem.Input name="name" required />
      <FormItem.Select
        name="grade"
        allowClear
        options={GradeEnumOptions}
        hidden={!model.parentId}
      />
    </Form>
  )
}
CoursewareCategoryForm.defaultModalProps = (props) => {
  return {title: props.model?.id ? '编辑分类' : '新增分类'}
}
const CoursewareCategoryPage: PageComponent = () => {
  const createSubCate = useCallback(async (data: CoursewareCategory) => {
    await overlay.showForm(CoursewareCategoryForm, {parentId: data.id})
  }, [])
  const onDelete = useCallback(async (data: CoursewareCategory) => {
    if (await overlay.deleteConfirm()) {
      await adminApi.coursewareCategory.delete(data.id)
    }
  }, [])
  return (
    <ApiTable
      defaultExpandedRowKeys={[]}
      api={adminApi.coursewareCategory.getPageList}
      tableTitle="课件分类列表"
      useTreeTable
      pagination={false}
      toolBar={[
        {
          title: '新增一级分类',
          component: CoursewareCategoryForm,
          buttonType: 'create',
        },
      ]}
      labels={tabLabels}
      columns={[
        {dataIndex: 'name'},
        {dataIndex: 'grade', width: 120, options: GradeEnumOptions},
        {
          title: '操作',
          width: 160,
          actions: [
            {
              component: CoursewareCategoryForm,
              title: '编辑',
            },
            {
              title: '新增子分类',
              onClick: createSubCate,
              hidden: (data) => !!data.parentId,
            },
            {
              onClick: onDelete,
              title: '删除',
            },
          ],
        },
      ]}></ApiTable>
  )
}

CoursewareCategoryPage.options = {
  name: '课件分类管理',
  path: '/exercise/courseware/category',
}

export default CoursewareCategoryPage
