import React, {useState, useMemo, useCallback} from 'react'
import {Form, Col, Row, Button, Input} from 'antd'
import {FormProps} from 'antd/lib/form/Form'
import {DownOutlined, UpOutlined} from '@ant-design/icons'
import {ColumnProps} from './interface'
import {FormItem} from '../form'
import useMediaQuery from '../../utils/useMediaQuery'
import {TableContext, useTable} from './ApiTable'
/**
 * 默认的查询表单配置
 */
const defaultColConfig = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 8,
  xl: 6,
  xxl: 6,
}

type SearchItemPorps<T> = {column: ColumnProps<T>}
export function SearchItem<T>(props: SearchItemPorps<T>) {
  let {column} = props
  let table = useTable()
  let controlProps = {label: column.title as string, name: column.dataIndex as string}
  if (column.options) {
    return (
      <FormItem.Select
        {...controlProps}
        options={table.options[column.dataIndex.toString()]}
        mode={column.operator === 'in' ? 'multiple' : undefined}
        hasAllOption={column.operator !== 'in'}
      />
    )
  }
  // return (
  //   <Form.Item>
  //     <Input />
  //   </Form.Item>
  // )
  return <FormItem.Input {...controlProps} />
}
const SearchForm: React.FC = (props) => {
  const table = useTable()
  const windowSize = useMediaQuery()
  let [form] = Form.useForm()
  const [expand, setExpand] = useState(false)
  const reset = useCallback(() => {
    form.resetFields()
    table.reset()
  }, [table, form])
  const searchColumns = table.columns.filter((c) => c.operator && c.dataIndex)
  if (searchColumns.length === 0) return null
  const span = defaultColConfig[windowSize] ?? 8
  const rowNumber = 24 / span
  let searchItems: any[] = []
  let showCount = expand
    ? searchColumns.length
    : Math.max(1, Math.min(rowNumber - 1, searchColumns.length))

  for (let index = 0; index < showCount; index++) {
    searchItems.push(
      <Col span={span} className="search-col" key={`col_${index}`}>
        <SearchItem column={searchColumns[index]} />
      </Col>,
    )
  }

  const expandSpan = () => {
    if (searchColumns.length < rowNumber) return null
    return (
      <Button type="link" style={{fontSize: 12}} onClick={() => setExpand(!expand)}>
        {expand ? '收起' : '展开'}
        {expand ? <UpOutlined /> : <DownOutlined />}
      </Button>
    )
  }
  const onFinish = () => {
    var data = form.getFieldsValue()
    table.search(data)
  }
  return (
    <Form form={form} className="search" {...props} onFinish={onFinish}>
      <Row>
        {searchItems}
        <Col span={span} key="sumbitButton" className="search-col">
          <FormItem.Item>
            <Button type="primary" htmlType="submit" className="search-button">
              查询
            </Button>
            {expandSpan()}
          </FormItem.Item>
        </Col>
      </Row>
    </Form>
  )
}
export default SearchForm
