import React, {useCallback, useState} from 'react'
import adminApi, {WordDTO, CoursewareType, SentenceDTO} from '@/api/admin'
import {ApiTable, Form, FormItem, overlay} from '@/components'
import CoursewareSideTree from './components/CoursewareSideTree'
import {Tooltip, message, Button} from 'antd'
import AudioIcon from '@/components/AudioIcon'
import LoadingAnchor from '@/components/loading/LoadingAnchor'
import {translate} from './components/translateUtils'
import UploadButton from '@/components/UploadButton'

const labels: Record<keyof SentenceDTO | string, string> = {
  en: '句子英译',
  cn: '句子中译',
  audioUrl: '音频文件',
  createdByName: '创建人',
  createdDate: '创建时间',
  updatedByName: '最后操作人',
  updatedDate: '最后操作时间',
  createTitle: '新增句子',
  updateTitle: '编辑句子',
}

const SentenceForm: ModalComponent<SentenceDTO> = (props) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState(props.model)
  const onClick = useCallback(async () => {
    let en: string = form.getFieldValue('en')
    if (!en) {
      message.warning('请输入' + labels.en)
      return
    }
    let res = await translate(en)
    if (res) {
      form.setFieldsValue(res)
      setFormData((prev) => ({...prev, ...form.getFieldsValue()}))
    }
  }, [form])
  return (
    <Form
      form={form}
      api={adminApi.sentence.save}
      initialValues={formData}
      labels={labels}>
      <Form.Item label={labels.en}>
        <FormItem.Input name="en" required noStyle style={{width: 240}} />
        <Tooltip title="自动获取">
          <LoadingAnchor onClick={onClick}>自动识别</LoadingAnchor>
        </Tooltip>
      </Form.Item>

      <FormItem.Input name="cn" required />
      <FormItem.Item name="audioUrl">
        <AudioIcon src={formData.audioUrl} />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <UploadButton
          title="上传音频"
          onSuccess={(audioUrl) => {
            form.setFieldsValue({audioUrl})
            setFormData((prev) => ({...prev, ...form.getFieldsValue()}))
          }}
        />
      </FormItem.Item>
    </Form>
  )
}
SentenceForm.defaultModalProps = (props) => ({
  title: props?.model?.id ? labels.updateTitle : labels.createTitle,
})
const SentencePage: PageComponent = () => {
  const [sentence, setSentence] = useState<SentenceDTO>({})
  const onDelete = useCallback(async (data: SentenceDTO) => {
    if (await overlay.deleteConfirm()) {
      await adminApi.sentence.delete(data.id)
    }
  }, [])

  return (
    <CoursewareSideTree type={CoursewareType.SENTENCE} onSelect={setSentence}>
      <ApiTable
        api={adminApi.sentence.getPageList}
        tableTitle="句子列表"
        toolBar={[
          {
            buttonType: 'create',
            component: SentenceForm,
            title: labels.createTitle,
            hidden: !sentence.coursewareId,
          },
        ]}
        labels={labels}
        data={sentence}
        pagination={false}
        onApiBefore={(data) => {
          return !!data.coursewareId
        }}
        columns={[
          {valueType: 'index'},
          {dataIndex: 'en'},
          {dataIndex: 'cn'},
          {dataIndex: 'audioUrl', valueType: 'audio'},
          {
            title: '操作',
            actions: [
              {
                component: SentenceForm,
                title: '编辑',
              },
              {
                onClick: onDelete,
                title: '删除',
              },
            ],
          },
        ]}
      />
    </CoursewareSideTree>
  )
}
SentencePage.options = {name: '数据字典', path: '/auth/system-option'}
export default SentencePage
